import { extendTheme } from "@chakra-ui/react";

const THEME = extendTheme({
    styles: {
        global: {
            body: {
                backgroundColor: "white",
                color: "gray.800",

            }
        }
    }
})

export default THEME;