import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

import Grid from "@mui/material/Grid";
// import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import SettingsIcon from "@mui/icons-material/Settings";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import AddIcon from "@mui/icons-material/Add";
// import { Divider } from "@mui/material";

import { DefaultLayout } from "../templates/DefaultLayout";

// type
import { FAQScanType, FAQContentsType } from "../../types/api/FAQScanType";
import {
  FAQCategoryEditType,
  FAQCategoryEditPostType,
} from "../../types/api/FAQCategoryEditType";
import { Button, IconButton, Typography } from "@mui/material";

function AdminFAQEditCategory() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(true);
  const [FAQData, setFAQData] = useState<FAQCategoryEditType[]>();
  const [selectedFAQCategoryId, setSelectedFAQCategoryId] =
    useState<string>("");
  const [deleteFAQCategory, setDeleteFAQCategory] = useState<
    FAQCategoryEditType[]
  >([]);
  useEffect(() => {
    const scanFAQData = async () => {
      const baseUrl =
        "https://bp6fjsioll.execute-api.ap-northeast-1.amazonaws.com/Dev/faq-scan";
      const postBody = {};
      await axios
        .post(baseUrl, postBody)
        .then((res) => {
          const rawData = res.data.Item as FAQScanType[];
          const rawDataCategorySorted = rawData.sort((a, b) =>
            a.faq_order > b.faq_order ? 1 : -1
          );
          const rawDataSorted: FAQCategoryEditType[] =
            rawDataCategorySorted.map((categoryData: FAQScanType) => ({
              faq_id: categoryData.faq_id,
              faq_name: categoryData.faq_name,
              faq_order: categoryData.faq_order,
              faq_visible: categoryData.faq_visible,
              contents: categoryData.contents.sort((a, b) =>
                a.contents_order > b.contents_order ? 1 : -1
              ),
              faq_edit_status: "nochange",
            }));
          setFAQData(rawDataSorted);
          setSelectedFAQCategoryId(rawDataSorted[0].faq_id);
        })
        .catch((err) => {
          console.log(err);
        });
      setLoading(false);
    };
    scanFAQData();
  }, []);

  const [subject, setSubject] = useState<string>("");
  const [answer, setAnswer] = useState<string>("");
  const [order, setOrder] = useState<number>();
  const [categoryId, setCategoryId] = useState<string>("");

  const handleSubjectChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSubject(event.target.value);
  };

  const handleChangeAnswer = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setAnswer(event.target.value);
  };

  const handleOrderChange =
    (index: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
      if (FAQData !== undefined) {
        const newFAQData = [...FAQData];
        newFAQData[index].faq_order = Number(event.target.value);
        setFAQData(newFAQData);
      }
    };

  const handleNameChange =
    (index: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
      if (FAQData !== undefined) {
        const newFAQData = [...FAQData];
        newFAQData[index].faq_name = event.target.value;
        if (newFAQData[index].faq_edit_status === "nochange")
          newFAQData[index].faq_edit_status = "edit";
        setFAQData(newFAQData);
      }
    };

  const handleAddCategory = () => {
    const blankCategory: FAQCategoryEditType = {
      contents: [],
      faq_id: "",
      faq_name: "",
      faq_order: 0,
      faq_visible: true,
      faq_edit_status: "add",
    };
    const newFAQData = FAQData ? [...FAQData, blankCategory] : [blankCategory];
    setFAQData(newFAQData);
  };

  const handleDeleteCategory = (index: number) => () => {
    if (FAQData) {
      const newFAQData = [...FAQData];
      if (FAQData[index].faq_id !== "") {
        const newDeleteFAQCategoryItem: FAQCategoryEditType = FAQData[index];
        newDeleteFAQCategoryItem.faq_edit_status = "delete";
        const newDeleteFAQCategory: FAQCategoryEditType[] = [
          ...deleteFAQCategory,
          newDeleteFAQCategoryItem,
        ];
        setDeleteFAQCategory(newDeleteFAQCategory);
      }
      newFAQData.splice(index, 1);
      setFAQData(newFAQData);
    }
  };

  const handleSubmitButton = async () => {
    if (FAQData) {
      const baseUrl =
        "https://zy2bouzqn2.execute-api.ap-northeast-1.amazonaws.com/Dev/faq-category-edit";
      const FAQCategoryEditedPostBody: FAQCategoryEditPostType[] = FAQData.map(
        (categoryItem) => ({
          faq_id: categoryItem.faq_id,
          faq_name: categoryItem.faq_name,
          faq_order: categoryItem.faq_order,
          faq_visible: categoryItem.faq_visible,
          faq_edit_status: categoryItem.faq_edit_status,
        })
      );
      const FAQCategoryDeletePostBody: FAQCategoryEditPostType[] =
        deleteFAQCategory.map((categoryItem) => ({
          faq_id: categoryItem.faq_id,
          faq_name: categoryItem.faq_name,
          faq_order: categoryItem.faq_order,
          faq_visible: categoryItem.faq_visible,
          faq_edit_status: categoryItem.faq_edit_status,
        }));
      const postBody: {
        Keys: { faq_category_list: FAQCategoryEditPostType[] };
      } = {
        Keys: {
          faq_category_list: [
            ...FAQCategoryEditedPostBody,
            ...FAQCategoryDeletePostBody,
          ],
        },
      };
      console.log(postBody);
      axios
        .post(baseUrl, postBody)
        .then((response) => {
          console.log(response);
          navigate("/admin/faq");
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  console.log(FAQData);
  console.log(deleteFAQCategory);

  return (
    <DefaultLayout pageTitle="FAQ Add">
      <link
        href="https://cdn.jsdelivr.net/npm/bootstrap@5.1.0/dist/css/bootstrap.min.css"
        rel="stylesheet"
        integrity="sha384-KyZXEAg3QhqLMpG8r+8fhAXLRk2vvoC2f3B09zVXn8CA5QIVfZOJ3BCsw2P0p/We"
        crossOrigin="anonymous"
      />
      {!loading && FAQData && (
        <Grid container spacing={1}>
          <Grid
            item
            xs={4}
            style={{
              paddingRight: "10px",
              borderStyle: "solid",
              borderColor: "rgba(0, 0, 0, 0.12)",
              borderWidth: "0 1px 0 0",
            }}
          >
            <div>Help Category</div>
            <List component="nav">
              {FAQData.map((FAQCategory) => (
                <ListItemButton
                  key={FAQCategory.faq_id}
                  onClick={() =>
                    navigate("/admin/faq", {
                      state: { defaultCategoryId: FAQCategory.faq_id },
                    })
                  }
                >
                  <ListItemText primary={FAQCategory.faq_name} />
                </ListItemButton>
              ))}
              <ListItemButton onClick={() => console.log("ClickAdd")}>
                <ListItemIcon sx={{ minWidth: 0, mr: 1 }}>
                  <SettingsIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText secondary="Help Category Setting" />
              </ListItemButton>
            </List>
          </Grid>
          <Grid
            item
            container
            xs={8}
            sx={{
              flexDirection: "row",
              alignItems: "center",
            }}
            spacing={1}
          >
            <Grid item xs={2} sx={{ mr: 1, ml: 1, mb: 1 }}>
              <Typography>Order</Typography>
            </Grid>
            <Grid item xs={8} sx={{ mr: 1, mb: 1 }}>
              <Typography>Help Category</Typography>
            </Grid>
            <Grid item xs={1} />
            {FAQData.map((FAQCategory, categoryIndex) => (
              <Grid container key={FAQCategory.faq_id}>
                <Grid item xs={2} sx={{ mr: 1, ml: 1, mb: 1 }}>
                  <input
                    type="number"
                    className="form-control"
                    id="order_input"
                    required
                    value={FAQCategory.faq_order}
                    onChange={handleOrderChange(categoryIndex)}
                  />
                  <div className="invalid-feedback">Input the title</div>
                </Grid>
                <Grid item xs={8} sx={{ mr: 1, mb: 1 }}>
                  <input
                    type="text"
                    className="form-control"
                    id="order_input"
                    required
                    value={FAQCategory.faq_name}
                    onChange={handleNameChange(categoryIndex)}
                  />
                  <div className="invalid-feedback">Input the title</div>
                </Grid>
                <Grid item xs={1} sx={{ mr: 1, mb: 1 }}>
                  <IconButton
                    onClick={handleDeleteCategory(categoryIndex)}
                    disabled={FAQCategory.contents.length !== 0}
                  >
                    <DeleteOutlineIcon />
                  </IconButton>
                </Grid>
              </Grid>
            ))}
            <Button
              color="inherit"
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              style={{ textTransform: "none" }}
              onClick={handleAddCategory}
            >
              <AddIcon sx={{ color: "gray" }} />
              <Typography sx={{ color: "gray" }}>Add Help Category</Typography>
            </Button>

            <div className="col-10 row justify-content-center mt-5">
              <div className="col-4">
                <button
                  type="button"
                  className="btn btn-outline-dark w-100"
                  onClick={() => navigate("../")}
                >
                  Turn Back
                </button>
              </div>
              <div className="col-4">
                <button
                  id="addProductCheckButton"
                  className="btn btn-outline-primary w-100"
                  type="button"
                  onClick={handleSubmitButton}
                >
                  Submit
                </button>
              </div>
            </div>
          </Grid>
        </Grid>
      )}
    </DefaultLayout>
  );
}

export default AdminFAQEditCategory;
