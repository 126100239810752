import { useEffect, useState } from "react";
import axios from "axios";

import Select from "react-select";

import IconButton from "@mui/material/IconButton";
import CancelIcon from "@mui/icons-material/Cancel";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import ClearIcon from "@mui/icons-material/Clear";

import { DefaultLayout } from "../templates/DefaultLayout";

import { PictureBinaryDataType } from "../../types/api/ProductRegistrationApiType";

import { CategoryInfoType } from "../../types/api/CategoryInfoType";

import "./css/products.css";
import { TagInfoType } from "../../types/api/TagInfoType";
import Button from "@mui/material/Button";
import { useNavigate, useParams } from "react-router-dom";
import {
  PictureDataType,
  prize_data,
  ProductDetailApiType,
} from "../../types/api/ProductDetailApiType";
import {
  PostPrizeDataType,
  CommitPictureDataType,
  ProductEditApiType,
  PrizeDataType,
} from "../../types/api/ProductEditApiType";

const indicator = (i: number) => {
  i = Math.abs(i);
  const cent = i % 100;
  if (cent >= 10 && cent <= 20) return `${i}th`;

  const dec = i % 10;
  if (dec === 1) return `${i}st`;
  if (dec === 2) return `${i}nd`;
  if (dec === 3) return `${i}rd`;
  return `${i}th`;
};

export const AdminProductEdit = () => {
  const { productId } = useParams();
  const [autoOrNot, setAutoOrNot] = useState<string>("");
  const [newOrNot, setNewOrNot] = useState<string>("");
  const [productTitle, setProductTitle] = useState<string>("");
  const [categoryName, setCategoryName] = useState<string>("");
  const [tagIds, setTagIds] = useState<string[]>([]);
  const [isoCode, setIsoCode] = useState<string>("php");
  // const [binaryDataList, setBinaryDataList] = useState<PictureBinaryDataType[]>(
  //   []
  // );
  const [binaryDataListArray, setBinaryDataListArray] = useState<
    Array<CommitPictureDataType[]>
  >([[]]);
  const [price, setPrice] = useState<number | string>("");
  const [lowestRank, setLowestRank] = useState<string>("");
  const [lowestRankTicketUnit, setLowestRankTicketUnit] = useState<string>("");
  const [publicationAt, setPublicationAt] = useState<string>("");
  const [publicationAtNow, setPublicationAtNow] = useState<string>("");
  const [rouletteAt, setRouletteAt] = useState<string>("");
  const [explain, setExplain] = useState<string>("");
  const [liveUrl, setLiveUrl] = useState<string>("");
  const [prizeInfo, setPrizeInfo] = useState<PrizeDataType[]>([
    {
      details: [
        {
          detail_contents: "",
          detail_title: "",
        },
      ],
      title: "",
      price_info: {
        price: 0,
        iso_code: "P$",
      },
    },
  ]);
  const [categoryList, setCategoryList] = useState<CategoryInfoType[]>([]);
  const [tagOptions, setTagOptions] = useState<
    { value: TagInfoType; label: string }[]
  >([]);
  const [selectedTagList, setSelectedTagList] = useState<TagInfoType[]>([]);
  const [imageList, setImageList] = useState<
    Array<Array<File | CommitPictureDataType>>
  >([[]]);
  const navigate = useNavigate();

  const handleChangeAutoOrNot = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setAutoOrNot(event.target.value);
  };

  const handleChangeNewOrNot = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewOrNot(event.target.value);
  };

  const handleChangeProductTitle = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setProductTitle(event.target.value);
  };

  const handleChangeCategoryName = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setCategoryName(event.target.value);
  };

  const handleChangePrice = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPrice(+event.target.value.replace(/,/g, ""));
  };

  const handleChangeProductTags = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setTagIds([...tagIds, event.target.value]);
  };

  const handleChangeTicketType = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setLowestRank(event.target.value);
  };

  const handleChangeLowestRankTicketUnit = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setLowestRankTicketUnit(event.target.value);
  };

  const handleChangePublicationAtNow = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPublicationAtNow(event.target.value);
  };

  const handleChangePublicationAt = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPublicationAt(event.target.value);
  };

  const handleChangeRouletteAt = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRouletteAt(event.target.value);
  };

  const handleChangeExplain = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setExplain(event.target.value);
  };

  const handleChangeLiveUrl = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLiveUrl(event.target.value);
  };

  const handleChangePrizeInfoTitle =
    (indexPrize: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
      let newPrizeInfo = [...prizeInfo];
      newPrizeInfo[indexPrize].title = event.target.value;
      setPrizeInfo(newPrizeInfo);
    };

  const handleChangePrizeInfoPrice =
    (indexPrize: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
      let newPrizeInfo = [...prizeInfo];
      newPrizeInfo[indexPrize].price_info.price = Number(event.target.value);
      setPrizeInfo(newPrizeInfo);
      let price = newPrizeInfo.reduce(
        (prev, curr) =>
          curr.price_info.price ? prev + curr.price_info.price : prev,
        0
      );
      setPrice(price);
    };

  const handleChangePrizeDetailTitle =
    (indexPrize: number) =>
    (indexDetails: number) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      let newPrizeInfo = [...prizeInfo];
      newPrizeInfo[indexPrize].details[indexDetails].detail_title =
        event.target.value;
      setPrizeInfo(newPrizeInfo);
    };

  const handleChangePrizeDetailContents =
    (indexPrize: number) =>
    (indexDetails: number) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      let newPrizeInfo = [...prizeInfo];
      newPrizeInfo[indexPrize].details[indexDetails].detail_contents =
        event.target.value;
      setPrizeInfo(newPrizeInfo);
    };

  const handleAddDetailsButton = (indexPrize: number) => () => {
    let newPrizeInfo = [...prizeInfo];
    newPrizeInfo[indexPrize].details = [
      ...newPrizeInfo[indexPrize].details,
      {
        detail_contents: "",
        detail_title: "",
      },
    ];
    setPrizeInfo(newPrizeInfo);
  };

  const handleAddPrizeButton = () => {
    const newPrizeInfo = [
      ...prizeInfo,
      {
        details: [
          {
            detail_contents: "",
            detail_title: "",
          },
        ],
        title: "",
        price_info: {
          price: 0,
          iso_code: "php",
        },
      },
    ];
    console.log(newPrizeInfo);
    setPrizeInfo(newPrizeInfo);
    setImageList([...imageList, []]);
    setBinaryDataListArray([...binaryDataListArray, []]);
  };
  console.log("binaryDataListArray");
  console.log(binaryDataListArray);

  console.log("imageList");
  console.log(imageList);

  useEffect(() => {
    const getCategory = async () => {
      const baseUrl =
        "https://bp6fjsioll.execute-api.ap-northeast-1.amazonaws.com/Dev/category-scan";
      const postBody = {};
      await axios
        .post(baseUrl, postBody)
        .then((response) => {
          setCategoryList(response.data.Item);
        })
        .catch((err) => {
          console.log(err);
        });
    };
    const getTag = async () => {
      const baseUrl =
        "https://bp6fjsioll.execute-api.ap-northeast-1.amazonaws.com/Dev/tag-scan";
      const postBody = {};
      await axios
        .post(baseUrl, postBody)
        .then((response) => {
          setTagOptions(
            response.data.Item.map((tag: TagInfoType) => {
              return { value: tag, label: tag.tag_name };
            })
          );
        })
        .catch((err) => {
          console.log(err);
        });
    };
    const getProductInfo = async () => {
      const baseUrl =
        "https://bp6fjsioll.execute-api.ap-northeast-1.amazonaws.com/Dev/product-get-info";
      const postBody = { Keys: { product_id: productId } };
      await axios
        .post(baseUrl, postBody)
        .then((response) => {
          const rawData: ProductDetailApiType = response.data.Item;
          console.log(rawData);
          setAutoOrNot(rawData.auto_or_not.toString());
          setNewOrNot(rawData.new_or_not.toString());
          setProductTitle(rawData.product_title);
          setCategoryName(rawData.category_name);
          setSelectedTagList(rawData.tag_details.flat(1));
          setPrice(rawData.price_info.price);
          setLowestRank(rawData.ticket_info.lowest_rank);
          setLowestRankTicketUnit(
            rawData.ticket_info.lowest_rank_ticket_unit.toString()
          );
          setPublicationAt(rawData.time_info.publication_at);
          setRouletteAt(rawData.time_info.roulette_at);
          setExplain(rawData.explain);
          setLiveUrl(rawData.live_url);
          const rawBinaryDataListArray = rawData.pictures.map(
            (pictureData) => ({
              ...pictureData,
              file_data: "",
            })
          );
          const newBinaryDataListArray: Array<CommitPictureDataType[]> = [];
          for (var i = 1; i <= rawData.prize_info.length; i++) {
            const regex = new RegExp("^" + indicator(i) + "_");
            const newBinaryDataList = rawBinaryDataListArray.filter(
              (rawBinaryData) => regex.test(rawBinaryData.file_name)
            );
            newBinaryDataListArray.push(newBinaryDataList);
          }
          console.log(newBinaryDataListArray);
          setBinaryDataListArray(newBinaryDataListArray);
          setImageList(newBinaryDataListArray);

          setPrizeInfo(rawData.prize_info);
        })
        .catch((error) => console.log(error));
    };
    getCategory();
    getTag();
    getProductInfo();
  }, []);

  const handleEachPrizeFileInput =
    (index: number) => async (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.files && event.target.files[0]) {
        const newImageList = [...imageList];
        newImageList[index].push(...Array.from(event.target.files));
        setImageList(newImageList);

        // binaryData化
        const newBinaryDataListArray = [...binaryDataListArray];
        const newBinaryDataList = [...newBinaryDataListArray[index]];
        console.log(newBinaryDataList);
        Array.from(event.target.files).forEach(async (image) => {
          const reader = new FileReader();
          reader.readAsDataURL(image);
          await new Promise<void>(
            (resolve) => (reader.onload = () => resolve())
          );
          if (typeof reader.result === "string") {
            newBinaryDataList.push({
              file_url: "",
              file_name: indicator(index + 1) + "_" + image.name,
              file_data: reader.result.split("base64,")[1],
            });
          }
        });
        console.log(
          newBinaryDataList.filter(
            (binaryData) => !(binaryData instanceof File)
          )
        );
        newBinaryDataListArray[index] = newBinaryDataList;
        setBinaryDataListArray(newBinaryDataListArray);
      }
    };

  const handleEachPrizeImageDeleteButton =
    (indexPrize: number) => (indexImage: number) => () => {
      const newBinaryDataListArray = [...binaryDataListArray];
      const newBinaryDataList = [...newBinaryDataListArray[indexPrize]];
      newBinaryDataList.splice(indexImage, 1);
      newBinaryDataListArray[indexPrize] = newBinaryDataList;
      setBinaryDataListArray(newBinaryDataListArray);

      const newImageList = [...imageList];
      newImageList[indexPrize].splice(indexImage, 1);
      setImageList(newImageList);
    };

  const handleDeletePrizeButton = (indexPrize: number) => () => {
    const newPrizeInfo = [...prizeInfo];
    newPrizeInfo.splice(indexPrize, 1);
    setPrizeInfo(newPrizeInfo);
    const newBinaryDataListArray = [...binaryDataListArray];
    newBinaryDataListArray.splice(indexPrize, 1);
    setBinaryDataListArray(newBinaryDataListArray);
    const newImageList = [...imageList];
    newImageList.splice(indexPrize, 1);
    setImageList(newImageList);
  };

  const handleDeletePrizeDetailButton =
    (indexPrize: number) => (indexDetails: number) => () => {
      const newPrizeInfo = [...prizeInfo];
      newPrizeInfo[indexPrize].details.splice(indexDetails, 1);
      setPrizeInfo(newPrizeInfo);
    };

  const handleSubmitButton = async () => {
    // photos.forEach((photo) => {})
    // var reader = new FileReader();
    // reader.onload = () => {
    //   if (typeof reader.result === "string") {
    //     setBinaryDataList([
    //       ...binaryDataList,
    //       {
    //         file_data: reader.result.split("base64,")[1],
    //         file_name: "test.jpg",
    //       },
    //     ]);
    //   }
    // };
    // for (const photo of photos) {
    //   reader.readAsDataURL(photo);
    // }
    // const postPictures = photos.reduce(
    //   (result: PictureBinaryDataType[], photo) => {
    //     const target_data: PictureBinaryDataType | undefined =
    //       binaryDataList.find(
    //         (binaryData) => binaryData.file_name === photo.name
    //       );
    //     return target_data
    //       ? [
    //           ...result,
    //           {
    //             file_name: photo.name,
    //             file_data: target_data.file_data,
    //           },
    //         ]
    //       : [...result];
    //   },
    //   []
    // );
    const postPictures: PictureBinaryDataType[] = binaryDataListArray
      .flat(1)
      .filter((binaryData) => !(binaryData instanceof File));
    console.log(postPictures);
    const DateTime = new Date();
    const baseUrl =
      "https://zy2bouzqn2.execute-api.ap-northeast-1.amazonaws.com/Dev/product-edit";
    const postPrizeInfo: PostPrizeDataType[] = prizeInfo.map(
      (prizeInfoItem, indexPrize) => ({
        rank: indexPrize + 1,
        title: prizeInfoItem.title,
        price_info: {
          iso_code: prizeInfoItem.price_info.iso_code,
          price: prizeInfoItem.price_info.price,
        },
        details: prizeInfoItem.details.map((prizeDetail, indexDetails) => ({
          detail_order: indexDetails + 1,
          ...prizeDetail,
        })),
      })
    );
    if (productId) {
      const postBody: ProductEditApiType = {
        Keys: {
          product_id: productId,
          auto_or_not: autoOrNot === "true",
          new_or_not: newOrNot === "true",
          product_title: productTitle,
          category_id: categoryList.filter(
            (category) => category.category_name === categoryName
          )[0].category_id,
          category_name: categoryName,
          tag_ids: selectedTagList.map((tag) => tag.tag_id),
          owner_id: "Admin",
          price_info: {
            iso_code: "php",
            price: Number(price),
          },
          ticket_info: {
            lowest_rank: lowestRank,
            lowest_rank_ticket_unit: Number(lowestRankTicketUnit),
          },
          time_info: {
            publication_at:
              publicationAt ||
              new Intl.DateTimeFormat("JP", {
                dateStyle: "medium",
                timeStyle: "medium",
              })
                .format(DateTime)
                .replace(/\//g, "-"),
            roulette_at: rouletteAt,
            create_at: Intl.DateTimeFormat("JP", {
              dateStyle: "medium",
              timeStyle: "medium",
            })
              .format(DateTime)
              .replace(/\//g, "-"),
          },
          prize_info: postPrizeInfo,
          explain: explain,
          pictures: postPictures,
          live_url: liveUrl,
        },
      };
      console.log(postBody);
      await axios
        .post(baseUrl, postBody)
        .then((response) => {
          console.log(response);
          navigate("../");
        })
        .catch((error) => console.log(error));
    }
  };

  console.log(prizeInfo);

  return (
    <DefaultLayout pageTitle="Product Edit">
      {/* xs/sm/md/lg/xl: 画面サイズに応じて横幅を調整する数値（1〜12） */}
      <link
        href="https://cdn.jsdelivr.net/npm/bootstrap@5.1.0/dist/css/bootstrap.min.css"
        rel="stylesheet"
        integrity="sha384-KyZXEAg3QhqLMpG8r+8fhAXLRk2vvoC2f3B09zVXn8CA5QIVfZOJ3BCsw2P0p/We"
        crossOrigin="anonymous"
      />
      <div className="container">
        <form className="needs-validation" noValidate>
          <div className="d-flex row justify-content-center mt-3">
            <label className="col-2 form-label fw-bold">Product ID</label>
            <div className="col-10">
              <span>{productId}</span>
            </div>
          </div>
          <div className="row mt-3">
            <label htmlFor="auto_or_not" className="col-2 form-label fw-bold">
              Roulette trigger*
            </label>
            <div className="col-10">
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  name="auto_or_not"
                  id="auto_or_not_true"
                  value="true"
                  checked={autoOrNot === "true"}
                  onChange={handleChangeAutoOrNot}
                />
                <label className="form-check-label" htmlFor="auto_or_not_true">
                  Automatic
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  name="auto_or_not"
                  id="auto_or_not_false"
                  value="false"
                  checked={autoOrNot === "false"}
                  onChange={handleChangeAutoOrNot}
                />
                <label className="form-check-label" htmlFor="auto_or_not_false">
                  Manual
                </label>
              </div>
            </div>
          </div>

          <div className="row mt-3">
            <label htmlFor="new_or_not" className="col-2 form-label fw-bold">
              New or Used*
            </label>
            <div className="col-10">
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  name="new_or_not"
                  id="new_or_not_true"
                  value="true"
                  checked={newOrNot === "true"}
                  onChange={handleChangeNewOrNot}
                />
                <label className="form-check-label" htmlFor="new_or_not_true">
                  Brand New
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  name="new_or_not"
                  id="new_or_not_false"
                  value="false"
                  checked={newOrNot === "false"}
                  onChange={handleChangeNewOrNot}
                />
                <label className="form-check-label" htmlFor="new_or_not_false">
                  Second hands
                </label>
              </div>
            </div>
          </div>

          <div className="row align-items-center mt-3">
            <label htmlFor="product_title" className="col-2 form-label fw-bold">
              Product title*
            </label>
            <div className="col-10">
              <input
                type="text"
                className="form-control"
                id="product_title"
                placeholder="ex) Summer T-shirt"
                required
                value={productTitle}
                onChange={handleChangeProductTitle}
              />
              <div className="invalid-feedback">Input the title</div>
            </div>
          </div>

          <div className="row align-items-center mt-3">
            <label htmlFor="category" className="col-2 form-label fw-bold">
              Product Category*
            </label>
            <div className="col-10">
              <select
                className="form-select"
                id="category"
                required
                value={categoryName}
                onChange={handleChangeCategoryName}
              >
                <option value="" disabled>
                  Choose...
                </option>
                {categoryList.map((category) => (
                  <option
                    value={category.category_name}
                    key={category.category_name}
                  >
                    {category.category_name}
                  </option>
                ))}
              </select>
              <div className="invalid-feedback">Select Category</div>
            </div>
          </div>

          <div className="row align-items-center mt-3">
            <label htmlFor="product_tags" className="col-2 form-label fw-bold">
              Product Tags*
            </label>
            <div className="col-10">
              <Select
                isMulti
                name="tags"
                options={tagOptions.filter(
                  (tagOption) =>
                    !selectedTagList
                      .map((selectedTag) => selectedTag.tag_name)
                      .includes(tagOption.label)
                )}
                value={selectedTagList.map((selectedTag) => ({
                  value: selectedTag,
                  label: selectedTag.tag_name,
                }))}
                className="basic-multi-select"
                classNamePrefix="select"
                onChange={(value) => {
                  setSelectedTagList(value.map((item) => item.value));
                }}
              />
            </div>
          </div>

          <div className="row align-items-center mt-3">
            <label htmlFor="product_price" className="col-2 form-label fw-bold">
              Product Price
            </label>
            <div className="col-10">
              <div className="input-group has-validation">
                <span className="input-group-text">P$</span>
                <input
                  type="tel"
                  disabled
                  className="form-control"
                  id="product_price"
                  placeholder="20,000"
                  required
                  value={price?.toLocaleString()}
                  onChange={handleChangePrice}
                />
                <div className="invalid-feedback">Input Price</div>
              </div>
            </div>
          </div>

          <div className="row align-items-center mt-3">
            <div className="col-sm-6">
              <label htmlFor="ticket_type" className="form-label fw-bold">
                Ticket Type
              </label>
              <select
                className="form-select"
                id="ticket_type"
                required
                onChange={handleChangeTicketType}
                value={lowestRank}
              >
                <option value="" disabled>
                  Choose...
                </option>
                <option value="NORMAL">Normal</option>
                <option value="GOLD">Gold</option>
                <option value="PREMIUM">Premium</option>
              </select>
              <div className="invalid-feedback">Select Ticket Type</div>
            </div>

            <div className="col-sm-6">
              <label htmlFor="amount_per_unit" className="form-label fw-bold">
                Ticket Amount for One Unit
              </label>
              <input
                type="number"
                className="form-control"
                id="amount_per_unit"
                placeholder="2"
                required
                value={lowestRankTicketUnit}
                onChange={handleChangeLowestRankTicketUnit}
              />
              <div className="invalid-feedback">Input Number</div>
            </div>
          </div>

          <div className="d-flex row justify-content-center mt-3">
            <label className="col-2 form-label fw-bold">Limit Sheets</label>
            <div className="col-10">
              <span>30 Sheets</span>
            </div>
          </div>

          <div className="d-flex row align-items-center mt-3">
            <div className="col-2">
              <label
                htmlFor="publish_now_or_not"
                className="form-label fw-bold mb-0"
              >
                Publication Schedule*
              </label>
            </div>
            <div className="col-10">
              <input
                type="text"
                className="form-control"
                id="publish_schedule"
                placeholder="YYYY-MM-DD HH:mm:SS"
                disabled={publicationAtNow === "true"}
                value={publicationAt}
                onChange={handleChangePublicationAt}
              />
              <div className="invalid-feedback">Input Schedule</div>
            </div>
          </div>

          <div className="d-flex row align-items-center mt-3">
            <div className="col-2">
              <label
                htmlFor="roulette_schedule"
                className="form-label fw-bold mb-0"
              >
                Roulette Schedule*
              </label>
            </div>
            <div className="col-10">
              <input
                type="text"
                className="form-control"
                id="roulette_schedule"
                placeholder="YYYY-MM-DD HH:mm:SS"
                required
                value={rouletteAt}
                onChange={handleChangeRouletteAt}
              />
              <div className="invalid-feedback">Input Schedule</div>
            </div>
          </div>

          <div className="row align-items-center mt-3">
            <label htmlFor="Explain" className="col-2 form-label fw-bold mb-0">
              Explain
            </label>
            <div className="col-10">
              <textarea
                className="form-control"
                id="Explain"
                placeholder="Please write a description about the product here."
                required
                value={explain}
                onChange={handleChangeExplain}
              ></textarea>
              <div className="invalid-feedback">Input Explain</div>
            </div>
          </div>

          <div className="row align-items-center mt-3">
            <label htmlFor="live_url" className="col-2 form-label fw-bold">
              Live URL
            </label>
            <div className="col-10">
              <input
                type="text"
                className="form-control"
                id="live_url"
                placeholder="https://www.youtube.com/"
                required
                value={liveUrl}
                onChange={handleChangeLiveUrl}
              />
              <div className="invalid-feedback">Input the title</div>
            </div>
          </div>

          {prizeInfo.map((prizeData, indexPrize) => (
            <div key={indicator(indexPrize + 1)}>
              <hr className="my-4" />

              <div className="d-flex align-items-center justify-content-between">
                <div className="vertical-align-center fs-5 fw-bold">
                  # {indicator(indexPrize + 1)} Prize
                </div>
                <div>
                  {prizeInfo.length > 1 && (
                    <Button
                      type="button"
                      color="inherit"
                      onClick={handleDeletePrizeButton(indexPrize)}
                    >
                      <DeleteOutlineIcon />
                    </Button>
                  )}
                </div>
              </div>

              <div className="row align-items-center mt-3">
                <label
                  htmlFor={indicator(indexPrize + 1) + "Title"}
                  className="col-2 form-label fw-bold"
                >
                  {indicator(indexPrize + 1)} Prize title*
                </label>
                <div className="col-10">
                  <input
                    type="text"
                    className="form-control"
                    id={indicator(indexPrize + 1) + "Title"}
                    placeholder="ex) Summer T-shirt"
                    required
                    onChange={handleChangePrizeInfoTitle(indexPrize)}
                    value={prizeData.title}
                  />
                  <div className="invalid-feedback">Input the title</div>
                </div>

                <label
                  htmlFor={indicator(indexPrize + 1) + "Price"}
                  className="col-2 form-label fw-bold mt-3"
                >
                  {indicator(indexPrize + 1)} Prize Price*
                </label>
                <div className="col-10 mt-3">
                  <div className="input-group has-validation">
                    <span className="input-group-text">P$</span>
                    <input
                      type="tel"
                      className="form-control"
                      id="product_price"
                      placeholder="20,000"
                      required
                      value={prizeData.price_info.price || ""}
                      onChange={handleChangePrizeInfoPrice(indexPrize)}
                    />
                    <div className="invalid-feedback">Input Price</div>
                  </div>
                </div>

                <label
                  htmlFor={indicator(indexPrize + 1) + "InputFile"}
                  className="col-2 form-label fw-bold"
                >
                  {indicator(indexPrize + 1)} Pictures*
                </label>

                <div className="col-10 mt-3">
                  <input
                    className="form-control"
                    id={indicator(indexPrize + 1) + "InputFile"}
                    type="file"
                    onChange={handleEachPrizeFileInput(indexPrize)}
                    accept=".jpg, .jpeg"
                    multiple
                  />
                </div>
                <div className="row align-items-center col-10 mt-3">
                  {imageList[indexPrize].map((image, indexImage) => {
                    if (!(image instanceof File)) {
                      return (
                        <div
                          style={{
                            position: "relative",
                            width: "40%",
                          }}
                          key={image.file_name}
                        >
                          <IconButton
                            aria-label="delete image"
                            style={{
                              position: "absolute",
                              top: 10,
                              left: 10,
                              color: "#aaa",
                            }}
                            onClick={handleEachPrizeImageDeleteButton(
                              indexPrize
                            )(indexImage)}
                          >
                            <CancelIcon />
                          </IconButton>
                          <img
                            src={image.file_url}
                            style={{
                              width: "100%",
                            }}
                            alt="uploadedPhoto"
                          />
                          <p>{image.file_name}</p>
                        </div>
                      );
                    } else if (image instanceof File) {
                      return (
                        <div
                          style={{
                            position: "relative",
                            width: "40%",
                          }}
                          key={image.name}
                        >
                          <IconButton
                            aria-label="delete image"
                            style={{
                              position: "absolute",
                              top: 10,
                              left: 10,
                              color: "#aaa",
                            }}
                            onClick={handleEachPrizeImageDeleteButton(
                              indexPrize
                            )(indexImage)}
                          >
                            <CancelIcon />
                          </IconButton>
                          <img
                            src={URL.createObjectURL(image)}
                            style={{
                              width: "100%",
                            }}
                            alt="uploadedPhoto"
                          />
                          {image && <p>{image.name}</p>}
                        </div>
                      );
                    }
                  })}
                </div>
              </div>

              <div className="mt-3">
                {prizeData.details.map((prizeDetail, indexDetails) => (
                  <div
                    className="row align-items-center"
                    key={
                      indicator(indexPrize + 1) +
                      "Prize" +
                      indicator(indexDetails + 1) +
                      "Detail"
                    }
                  >
                    <div className="col-sm-5 mt-2">
                      <label
                        htmlFor={`${indicator(indexPrize + 1)}Prize${indicator(
                          indexDetails + 1
                        )}DetailSubject`}
                        className="form-label fw-bold"
                      >
                        {indicator(indexDetails + 1)} Detail Subject
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id={`${indicator(indexPrize + 1)}Prize${indicator(
                          indexDetails + 1
                        )}DetailSubject`}
                        placeholder=""
                        value={prizeDetail.detail_title}
                        onChange={handleChangePrizeDetailTitle(indexPrize)(
                          indexDetails
                        )}
                        required
                      />
                    </div>

                    <div className="col-sm-5 mt-2">
                      <label
                        htmlFor={`${indicator(indexPrize + 1)}Prize${indicator(
                          indexDetails + 1
                        )}DetailContents`}
                        className="form-label fw-bold"
                      >
                        {indicator(indexDetails + 1)} Detail Contents
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id={`${indicator(indexPrize + 1)}Prize${indicator(
                          indexDetails + 1
                        )}DetailContents`}
                        placeholder=""
                        value={prizeDetail.detail_contents}
                        onChange={handleChangePrizeDetailContents(indexPrize)(
                          indexDetails
                        )}
                        required
                      />
                    </div>

                    <div className="col-sm-1 mt-2">
                      <label className="form-label fw-bold"></label>
                      {prizeData.details.length > 1 && (
                        <Button
                          className="btn"
                          onClick={handleDeletePrizeDetailButton(indexPrize)(
                            indexDetails
                          )}
                        >
                          <ClearIcon />
                        </Button>
                      )}
                    </div>
                  </div>
                ))}
              </div>
              <div className="row justify-content-center mt-2">
                <div className="col-10 mt-3">
                  <button
                    type="button"
                    className="btn btn-sm btn-outline-secondary w-100"
                    onClick={handleAddDetailsButton(indexPrize)}
                  >
                    Add Details +
                  </button>
                </div>
              </div>
            </div>
          ))}

          <hr className="my-4" />

          <div className="row justify-content-center mb-5">
            <div className="col-12 mt-3">
              <button
                className="btn btn-sm btn-outline-success w-100"
                type="button"
                onClick={handleAddPrizeButton}
              >
                Add prizes +
              </button>
            </div>
          </div>

          <div className="row justify-content-center mb-5">
            <div className="col-3">
              <button
                type="button"
                className="btn btn-outline-dark w-100"
                onClick={() => navigate(`../detail/${productId}`)}
              >
                Turn Back
              </button>
            </div>
            <div className="col-3">
              <button
                id="addProductCheckButton"
                className="btn btn-outline-primary w-100"
                type="button"
                data-bs-toggle="modal"
                data-bs-target="#addProductCheckModal"
                onClick={handleSubmitButton}
              >
                Submit
              </button>
            </div>
          </div>
        </form>
      </div>
    </DefaultLayout>
  );
};
