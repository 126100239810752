import React, { useEffect, useState } from "react";
import EditIcon from "@mui/icons-material/Edit";

import { DefaultLayout } from "../templates/DefaultLayout";
import IconButton from "@mui/material/IconButton";
import { CategoryScanInfoType } from "../../types/api/CategoryInfoType";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const AdminCategoryList = () => {
  // // Amazon Cognito 認証情報プロバイダーを初期化します
  // AWS.config.region = "ap-northeast-1"; // リージョン
  // AWS.config.credentials = new AWS.CognitoIdentityCredentials({
  //   IdentityPoolId: "ap-northeast-1:46661df7-2372-48e3-b692-1c1e88c8e3cc",
  // });

  const [categoryList, setCategoryList] = useState<CategoryScanInfoType[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [displaySettingVisible, setDisplaySettingVisible] =
    useState<boolean>(true);
  const [displaySettingUnvisible, setDisplaySettingUnvisible] =
    useState<boolean>(true);
  const navigate = useNavigate();

  useEffect(() => {
    const getCategoryList = async (): Promise<void> => {
      setIsLoading(true);
      const baseUrl =
        "https://bp6fjsioll.execute-api.ap-northeast-1.amazonaws.com/Dev/category-scan";
      const postBody = {};
      await axios.post(baseUrl, postBody).then((response) => {
        setCategoryList(response.data.Item);
      });
      setIsLoading(false);
    };
    getCategoryList();
  }, []);
  const handleRegistrationCategory = () => {
    navigate("registration");
  };

  const handleEditCategory = (categoryId: string) => () => {
    navigate("edit/" + categoryId);
  };

  const handleDisplayVisibleButton = () => {
    setDisplaySettingVisible(!displaySettingVisible);
  };

  const handleDisplayUnvisibleButton = () => {
    setDisplaySettingUnvisible(!displaySettingUnvisible);
  };

  const funcFilterCategory = (categoryItem: CategoryScanInfoType) => {
    return categoryItem.category_visible
      ? displaySettingVisible
      : displaySettingUnvisible;
  };

  console.log(categoryList);
  return (
    <DefaultLayout pageTitle="Categories List">
      <link
        href="https://cdn.jsdelivr.net/npm/bootstrap@5.1.0/dist/css/bootstrap.min.css"
        rel="stylesheet"
        integrity="sha384-KyZXEAg3QhqLMpG8r+8fhAXLRk2vvoC2f3B09zVXn8CA5QIVfZOJ3BCsw2P0p/We"
        crossOrigin="anonymous"
      />
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center mt-3 mb-4 pl-4 pr-4">
        <div className="row align-items-center">
          <div className="col">
            <div
              className="btn-toolbar"
              role="toolbar"
              aria-label="Toolbar with button groups"
            >
              <div
                className="btn-group me-2"
                role="group"
                aria-label="First group"
              >
                <button
                  type="button"
                  className={
                    displaySettingVisible
                      ? "btn btn-sm btn-success"
                      : "btn btn-sm btn-outline-success"
                  }
                  onClick={handleDisplayVisibleButton}
                >
                  Visible
                </button>
              </div>
              <div
                className="btn-group me-2"
                role="group"
                aria-label="Second group"
              >
                <button
                  type="button"
                  className={
                    displaySettingUnvisible
                      ? "btn btn-sm btn-danger"
                      : "btn btn-sm btn-outline-danger"
                  }
                  onClick={handleDisplayUnvisibleButton}
                >
                  Unvisible
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="text-right">
          <button
            type="button"
            className="btn btn-sm btn-outline-primary d-flex align-items-center"
            onClick={handleRegistrationCategory}
          >
            <span>Add Category</span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              className="bi bi-plus-circle ms-1"
              viewBox="0 0 16 16"
            >
              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
              <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
            </svg>
          </button>
        </div>
      </div>

      <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 g-3">
        {isLoading && <div className="col">Loading</div>}
        {categoryList.filter(funcFilterCategory).map((category) => (
          <div className="col" key={category.category_name}>
            <div className="card border-2 border-secondary rounded-3">
              <div className="card-header bg-secondary text-white rounded-top">
                <div className="d-flex justify-content-between align-items-center fs-5 fw-bold">
                  <span>{category.category_name}</span>
                  <IconButton
                    color="inherit"
                    sx={{ width: 16, height: 16 }}
                    onClick={handleEditCategory(category.category_id)}
                  >
                    <EditIcon sx={{ width: 20, height: 20 }} />
                  </IconButton>
                </div>
              </div>
              <div className="card-body">
                <ul className="nav flex-column d-flex flex-wrap mb-auto">
                  <li>
                    <p
                      className="card-text fw-medium mb-0"
                      style={{ fontSize: 8, flexWrap: "wrap" }}
                    >
                      Category ID : {category.category_id}
                    </p>
                  </li>
                  <li className="mt-1">
                    <div className="d-flex flex-wrap align-items-center">
                      {category.category_visible ? (
                        <span className="badge rounded-pill bg-success text-white fw-normal">
                          Visible
                        </span>
                      ) : (
                        <span className="badge rounded-pill bg-danger text-white fw-normal">
                          Unvisible
                        </span>
                      )}
                    </div>
                  </li>
                  <li
                    className="mt-2"
                    style={{ flexWrap: "wrap", fontSize: 12 }}
                  >
                    <p className="card-text fw-bold mb-0">Eye catch Picture</p>
                    <img
                      src={category.category_eye_catch}
                      alt={"image:" + category.category_name}
                    />
                  </li>
                </ul>
              </div>
            </div>
          </div>
        ))}
      </div>
    </DefaultLayout>
  );
};

export default AdminCategoryList;
