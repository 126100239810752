import { CircularProgress, Typography } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import Grid from "@mui/material/Grid";

import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Label,
  LabelList,
  ResponsiveContainer,
} from "recharts";
import {
  PictureDataType,
  ProductDetailApiType,
} from "../../types/api/ProductDetailApiType";

import { DefaultLayout } from "../templates/DefaultLayout";

type ChartDataType = { name: string; rate: number };

const indicator = (i: number) => {
  i = Math.abs(i);
  const cent = i % 100;
  if (cent >= 10 && cent <= 20) return `${i}th`;

  const dec = i % 10;
  if (dec === 1) return `${i}st`;
  if (dec === 2) return `${i}nd`;
  if (dec === 3) return `${i}rd`;
  return `${i}th`;
};

const makePictureGroup = (array: PictureDataType[], prizeAmount: number) => {
  const res: PictureDataType[][] = [];
  if (array) {
    const regex = new RegExp(`^products_`);
    const pushArray = array.filter((item) => regex.test(item.file_name));
    res.push(pushArray);
    for (let i = 1; i <= prizeAmount; i++) {
      const regex2 = new RegExp("^" + indicator(i) + "_");
      const pushArray2 = array.filter((item) => regex2.test(item.file_name));
      res.push(pushArray2);
    }
    console.log(res);
  }
  return res;
};

const AdminProductDetail = () => {
  const navigate = useNavigate();
  const { productId } = useParams();
  console.log(productId);
  const [product, setProduct] = useState<ProductDetailApiType>();
  const [displayScheduleTitle, setDisplayScheduleTitle] = useState<string>("");
  const [displaySchedule, setDisplaySchedule] = useState<string>("");
  const [chartData, setChartData] = useState<ChartDataType[]>();
  const [imageList, setImageList] = useState<PictureDataType[][]>([[]]);
  const [loading, setLoading] = useState<boolean>(true);
  useEffect(() => {
    const getProductInfo = async () => {
      const baseUrl =
        "https://bp6fjsioll.execute-api.ap-northeast-1.amazonaws.com/Dev/product-get-info";
      const postBody = { Keys: { product_id: productId } };
      await axios
        .post(baseUrl, postBody)
        .then((response) => {
          const rawData: ProductDetailApiType = response.data.Item;
          console.log(rawData);
          setProduct(rawData);
          setImageList(
            makePictureGroup(rawData.pictures, rawData.prize_info.length)
          );
          let rate = 0;
          switch (rawData.status) {
            case "Before Published":
              setDisplayScheduleTitle("Publishing Schedule");
              setDisplaySchedule(rawData.time_info.publication_at);
              break;
            case "Published":
              setDisplayScheduleTitle("Roulette Schedule");
              setDisplaySchedule(rawData.time_info.roulette_at);
              rate =
                1 -
                (rawData.sheets_info.rest / rawData.sheets_info.limit) * 100;
              setChartData([{ rate, name: "Participating Rate" }]);
              break;
            case "Shipping":
              setDisplayScheduleTitle("Shipping Schedule");
              setDisplaySchedule(rawData.time_info.shipped_at);
              rate =
                (rawData.prize_info.reduce(
                  (prev, curr) =>
                    prev + Number(curr.delivery_info.delivery_at !== ""),
                  0
                ) /
                  rawData.prize_info.length) *
                100;
              setChartData([{ rate, name: "Delevery Rate" }]);

              break;
            case "Done":
              setDisplayScheduleTitle("Shipping Schedule");
              setDisplaySchedule(rawData.time_info.shipped_at);
              rate =
                (rawData.prize_info.reduce(
                  (prev, curr) =>
                    prev + Number(curr.delivery_info.delivery_at !== ""),
                  0
                ) /
                  rawData.prize_info.length) *
                100;
              setChartData([{ rate, name: "Delevery Rate" }]);
              break;
          }
        })
        .catch((error) => console.log(error));
    };
    getProductInfo();
    setLoading(false);
  }, []);
  console.log(product);

  return (
    <DefaultLayout pageTitle="Product Detail">
      <link
        href="https://cdn.jsdelivr.net/npm/bootstrap@5.1.0/dist/css/bootstrap.min.css"
        rel="stylesheet"
        integrity="sha384-KyZXEAg3QhqLMpG8r+8fhAXLRk2vvoC2f3B09zVXn8CA5QIVfZOJ3BCsw2P0p/We"
        crossOrigin="anonymous"
      />

      {loading ? (
        <Grid
          item
          xs={12}
          sx={{ justifyContent: "center", textAlign: "center" }}>
          <CircularProgress color="primary" />
        </Grid>
      ) : (
        <div className="container">
          <div className="card border-2 border-secondary rounded-3 text-center">
            <div className="card-header bg-secondary text-white rounded-top">
              <div className="fs-5 fw-bold">{product?.product_title}</div>
            </div>
            <div className="card-body bg-white">
              <ul className="nav flex-column mb-auto">
                <li>
                  <p className="card-text fw-bold mb-0">
                    Product ID : {productId}
                  </p>
                </li>
                <li className="mt-3">
                  <div className="">
                    <span className="badge rounded-pill bg-info text-white fw-normal">
                      {product?.status}
                    </span>
                  </div>
                </li>
                <li className="mt-3 text-center">
                  <p className="card-text fw-bold mb-0">
                    {displayScheduleTitle}
                  </p>
                  <div className="d-flex justify-content-center align-items-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      fill="currentColor"
                      className="bi bi-clock me-1"
                      viewBox="0 0 16 16">
                      <path d="M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71V3.5z" />
                      <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0z" />
                    </svg>
                    <span className="fs-4">{displaySchedule}</span>
                  </div>
                  {displayScheduleTitle === "Roulette Schedule" && (
                    <div className="d-flex justify-content-center align-items-center mt-3">
                      <button
                        id="addProductCheckButton"
                        className="btn btn-outline-primary w-50"
                        type="button"
                        onClick={() => navigate("/roulette/" + productId)}>
                        Roulette Start
                      </button>
                    </div>
                  )}
                </li>
                {chartData && (
                  <li className="mt-3 text-center">
                    <Typography>{chartData[0].name}</Typography>
                    <ResponsiveContainer
                      className="container"
                      width="90%"
                      height={80}>
                      <BarChart layout="vertical" data={chartData}>
                        <XAxis type="number" domain={[0, 100]} />
                        <YAxis type="category" dataKey="name" hide />
                        <Bar dataKey="rate" fill="#82ca9d">
                          <LabelList dataKey="rate" position="center" />
                        </Bar>
                      </BarChart>
                    </ResponsiveContainer>
                  </li>
                )}
              </ul>
            </div>
          </div>
          <div className="mt-3 d-flex align-items-center justify-content-center">
            <div className="card border-2 border-secondary rounded-3 text-center d-flex flex-row justify-content-around col-6 me-1">
              <div className="m-1 col-6">
                <p className="mb-0 ms-1 me-1">Limit sheets</p>
                <p
                  className="m-0 ms-1 me-1"
                  style={{ fontSize: 25, fontWeight: "bold" }}>
                  {product?.sheets_info.limit}
                </p>
              </div>
              <div className="m-1 col-6">
                <p className="mb-0 ms-1 me-1">Entries</p>
                <p
                  className="m-0 ms-1 me-1"
                  style={{ fontSize: 25, fontWeight: "bold" }}>
                  {Number(product?.sheets_info.limit) -
                    Number(product?.sheets_info.rest)}
                </p>
              </div>
            </div>
            <div className="card border-2 border-secondary rounded-3 text-center d-flex flex-row justify-content-around col-6 me-1">
              <div className="m-1 col-4">
                <p className="mb-0 ms-1 me-1">Normal</p>
                <p
                  className="m-0 ms-1 me-1"
                  style={{ fontSize: 25, fontWeight: "bold" }}>
                  {product?.entry_users.participants.reduce(
                    (prev, cur) => prev + cur.tickets.normal,
                    0
                  )}
                </p>
              </div>
              <div className="m-1 col-4">
                <p className="mb-0 ms-1 me-1">Gold</p>
                <p
                  className="m-0 ms-1 me-1"
                  style={{ fontSize: 25, fontWeight: "bold" }}>
                  {product?.entry_users.participants.reduce(
                    (prev, cur) => prev + cur.tickets.gold,
                    0
                  )}
                </p>
              </div>
              <div className="m-1 col-4">
                <p className="mb-0 ms-1 me-1">Premium</p>
                <p
                  className="m-0 ms-1 me-1"
                  style={{ fontSize: 25, fontWeight: "bold" }}>
                  {product?.entry_users.participants.reduce(
                    (prev, cur) => prev + cur.tickets.premium,
                    0
                  )}
                </p>
              </div>
            </div>
          </div>
          {displayScheduleTitle === "Shipping Schedule" && (
            <div className="mt-3 d-flex flex-column align-items-center justify-content-start">
              <div className="col-12">Winner information</div>
              {product?.prize_info.map((prize) => (
                <div
                  className="card border-2 border-secondary rounded-3 d-flex flex-row justify-content-around col-12 p-1"
                  key={prize.rank}>
                  <div className="m-1 col-2">
                    <p className="mb-0 ms-1 me-1">
                      {indicator(prize.rank)} Prize
                    </p>
                  </div>
                  <div className="m-1 col-2">
                    <p className="mb-1 ms-1 me-1">User ID</p>
                    <p className="mb-1 ms-1 me-1">User Name</p>
                    <p className="mb-1 ms-1 me-1">Address</p>
                  </div>
                  <div className="m-1 col-5">
                    <p className="mb-1 ms-1 me-1">{prize.winner_id}</p>
                    <p className="mb-1 ms-1 me-1">{prize.winner_name}</p>
                    <p className="mb-1 ms-1 me-1">-</p>
                  </div>
                  <div className="m-1 col-2">
                    <button
                      type="button"
                      className={`btn ${
                        prize?.delivery_info?.delivery_status === "Preparing"
                          ? "btn-primary"
                          : "btn-outline-primary"
                      } mb-1 ms-1 me-1`}
                      style={{
                        fontSize: 14,
                        paddingTop: 0.5,
                        paddingBottom: 0.5,
                        paddingLeft: 1,
                        paddingRight: 1,
                        width: 80,
                      }}>
                      Preparing
                    </button>
                    <button
                      type="button"
                      className={`btn ${
                        prize?.delivery_info?.delivery_status === "Shipping"
                          ? "btn-primary"
                          : "btn-outline-primary"
                      } mb-1 ms-1 me-1`}
                      style={{
                        fontSize: 14,
                        paddingTop: 0.5,
                        paddingBottom: 0.5,
                        paddingLeft: 1,
                        paddingRight: 1,
                        width: 80,
                      }}>
                      Shipping
                    </button>
                    <button
                      type="button"
                      className={`btn ${
                        prize?.delivery_info?.delivery_status === "Delivered"
                          ? "btn-primary"
                          : "btn-outline-primary"
                      } mb-1 ms-1 me-1`}
                      style={{
                        fontSize: 14,
                        paddingTop: 0.5,
                        paddingBottom: 0.5,
                        paddingLeft: 1,
                        paddingRight: 1,
                        width: 80,
                      }}>
                      Delivered
                    </button>
                  </div>
                </div>
              ))}
            </div>
          )}
          <div className="mt-3 d-flex flex-column align-items-center justify-content-center">
            <div className="m-1 col-10">
              <p className="mb-0 ms-1 me-1">
                New or Used* :
                <span style={{ color: "#FF4E00" }}>
                  {product?.new_or_not ? " New" : " Used"}
                </span>
              </p>
            </div>
            <div className="m-1 col-10">
              <p className="mb-0 ms-1 me-1">
                Product Title* :
                <span style={{ color: "#FF4E00" }}>
                  {" "}
                  {product?.product_title}
                </span>
              </p>
            </div>
            <div className="m-1 col-10">
              <p className="mb-0 ms-1 me-1">
                Category* :
                <span style={{ color: "#FF4E00" }}>
                  {" " + product?.category_name}
                </span>
              </p>
            </div>
            <div className="m-1 col-10">
              <p className="mb-0 ms-1 me-1">Tags*</p>

              <div className="col-12 ms-1 me-1 d-flex flex-row">
                {product?.tag_details.flat(1).map((tag) => (
                  <div
                    className="align-items-center fs-6 fw-bold pr-1 pl-1 mb-1 me-2"
                    style={{
                      border: "1px solid",
                      borderRadius: 4,
                      borderColor: tag.tag_color,
                      color: tag.tag_color,
                    }}
                    key={tag.tag_id}>
                    <span style={{ marginRight: "10px", marginLeft: "10px" }}>
                      {tag.tag_name}
                    </span>
                  </div>
                ))}
              </div>
            </div>
            <div className="m-1 col-10">
              <p className="mb-0 ms-1 me-1">
                Price* :
                <span style={{ color: "#FF4E00" }}>
                  P${product?.price_info.price.toLocaleString()}-
                </span>
              </p>
            </div>
            <div className="m-1 col-10">
              <p className="mb-0 ms-1 me-1">
                Ticket Type* :
                <span style={{ color: "#FF4E00" }}>
                  {product?.ticket_info.lowest_rank}
                </span>
              </p>
            </div>
            <div className="m-1 col-10">
              <p className="mb-0 ms-1 me-1">
                Number of tickets required for 1 sheet* :
                <span style={{ color: "#FF4E00" }}>
                  {product?.ticket_info.lowest_rank_ticket_unit}
                </span>
              </p>
            </div>
            <div className="m-1 col-10">
              <p className="mb-0 ms-1 me-1">
                Limit Number of Sheets* :
                <span style={{ color: "#FF4E00" }}>
                  {product?.sheets_info.limit} Sheets
                </span>
              </p>
            </div>
            <div className="m-1 col-10">
              <p className="mb-0 ms-1 me-1">
                Publication time* :
                <span style={{ color: "#FF4E00" }}>
                  {product?.time_info.publication_at}
                </span>
              </p>
            </div>
            <div className="m-1 col-10">
              <p className="mb-0 ms-1 me-1">
                Roulette Schedule :
                <span style={{ color: "#FF4E00" }}>
                  {product?.time_info.roulette_at}
                </span>
              </p>
            </div>
            <div className="m-1 col-10">
              <p className="mb-0 ms-1 me-1">Explain* :</p>
              <div className="col-12 ms-1 me-1" style={{ color: "#FF4E00" }}>
                <p className="mb-0">{product?.explain}</p>
              </div>
            </div>
            <div className="m-1 col-10">
              <p className="mb-0 ms-1 me-1">Pictures :</p>
              <div className="row m-1 col-10">
                {imageList[0]?.map((picture) => (
                  <img
                    src={picture.file_url}
                    style={{
                      width: "30%",
                    }}
                    alt={picture.file_name}
                    key={picture.file_name}
                  />
                ))}
              </div>
            </div>
            <div className="col-10">
              <hr className="my-4" />
            </div>

            {product?.prize_info.map((prize, prizeIndex) => (
              <div className="m-1 col-10" key={prize.rank}>
                <p className="mb-0 ms-1 me-1 mt-1">1st Prize</p>
                <p className="mb-0 ms-1 me-1 mt-1">
                  prize title* :
                  <span style={{ color: "#FF4E00" }}>{prize.title}</span>
                </p>
                <p className="mb-0 ms-1 me-1 mt-1">
                  prize price* :
                  <span style={{ color: "#FF4E00" }}>
                    P$ {prize.price_info.price.toLocaleString()}-
                  </span>
                </p>
                {prize.details.map((detail) => (
                  <div key={detail.detail_order}>
                    <p className="mb-0 ms-1 me-1" style={{ color: "#FF4E00" }}>
                      Size: M
                    </p>
                    <p className="mb-0 ms-1 me-1" style={{ color: "#FF4E00" }}>
                      Made in: Japan
                    </p>
                  </div>
                ))}
                <p className="mb-0 ms-1 me-1 mt-1">Pictures* :</p>
                <div className="d-flex row m-1 col-10">
                  {imageList[prizeIndex + 1]?.map((image) => (
                    <img
                      src={image.file_url}
                      style={{
                        width: "30%",
                      }}
                      alt={image.file_name}
                      key={image.file_name}
                    />
                  ))}
                </div>
                <hr className="my-4" />
              </div>
            ))}
          </div>
          <div className="d-flex row justify-content-center mb-5">
            <div className="col-3">
              <button
                type="button"
                className="btn btn-outline-dark w-100"
                onClick={() => navigate("../")}>
                Turn Back
              </button>
            </div>
            <div className="col-3">
              <button
                id="addProductCheckButton"
                className="btn btn-outline-primary w-100"
                type="button"
                onClick={() => navigate("../edit/" + productId)}>
                Edit
              </button>
            </div>
          </div>
        </div>
      )}
    </DefaultLayout>
  );
};

export default AdminProductDetail;
