import { Typography } from "@mui/material";
import React, { useEffect, useState } from "react";

import EditIcon from "@mui/icons-material/Edit";
import IconButton from "@mui/material/IconButton";
import { DefaultLayout } from "../templates/DefaultLayout";
import { useNavigate } from "react-router-dom";
import { TagInfoType } from "../../types/api/TagInfoType";
import axios from "axios";

const AdminTagList = () => {
  const navigate = useNavigate();
  const handleEditTagButton = (tagId: string) => () => {
    navigate("edit/" + tagId);
  };

  const [searchInput, setSearchInput] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [tagList, setTagList] = useState<TagInfoType[]>([]);
  useEffect(() => {
    const getTagList = async () => {
      setIsLoading(true);
      const baseUrl =
        "https://bp6fjsioll.execute-api.ap-northeast-1.amazonaws.com/Dev/tag-scan";
      const postBody = {};
      await axios
        .post(baseUrl, postBody)
        .then((response) => {
          const newTagList: TagInfoType[] = response.data.Item;
          setTagList(newTagList);
        })
        .catch((error) => {
          console.log(error);
        });
    };
    getTagList();
    setIsLoading(false);
  }, []);

  return (
    <DefaultLayout pageTitle="Tags List">
      <link
        href="https://cdn.jsdelivr.net/npm/bootstrap@5.1.0/dist/css/bootstrap.min.css"
        rel="stylesheet"
        integrity="sha384-KyZXEAg3QhqLMpG8r+8fhAXLRk2vvoC2f3B09zVXn8CA5QIVfZOJ3BCsw2P0p/We"
        crossOrigin="anonymous"
      />
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center mt-3 mb-4 pl-4 pr-4">
        <div style={{ width: "auto" }}>
          <input
            className="form-control"
            type="search"
            placeholder="Search Tags"
            value={searchInput}
            onChange={(e) => setSearchInput(e.target.value)}
          />
        </div>
        <div className="text-right">
          <button
            type="button"
            className="btn btn-sm btn-outline-primary d-flex align-items-center"
            onClick={() => navigate("registration")}
          >
            <span>Add Tag</span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              className="bi bi-plus-circle ms-1"
              viewBox="0 0 16 16"
            >
              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
              <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
            </svg>
          </button>
        </div>
      </div>
      <div className="row">
        <div className="d-flex flex-row flex-wrap justify-content-start ">
          {isLoading && <div>Loading...</div>}
          {tagList
            .filter((tag: TagInfoType) =>
              tag.tag_name.toLowerCase().includes(searchInput.toLowerCase())
            )
            .map((tag) => (
              <div
                className="d-flex justify-content-between align-items-center fs-6 fw-bold pr-1 pl-1 mb-2 me-2"
                style={{
                  border: "1px solid",
                  borderRadius: 4,
                  borderColor: tag.tag_color,
                  color: tag.tag_color,
                }}
                key={tag.tag_id}
              >
                <span style={{ marginRight: "10px", marginLeft: "5px" }}>
                  {tag.tag_name}
                </span>
                <IconButton
                  color="inherit"
                  sx={{ width: 16, height: 16, marginRight: "5px" }}
                  onClick={handleEditTagButton(tag.tag_id)}
                >
                  <EditIcon sx={{ width: 16, height: 16 }} />
                </IconButton>
              </div>
            ))}
        </div>
      </div>
    </DefaultLayout>
  );
};

export default AdminTagList;
