import { IconButton } from "@mui/material";
import Button from "@mui/material/Button";
import axios from "axios";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { DefaultLayout } from "../templates/DefaultLayout";

const AdminTagRegistration = () => {
  const [tagTitle, setTagTitle] = useState<string>("");
  const [color, setColor] = useState<string>("#4AC6B6");
  const navigate = useNavigate();
  const handleSubmitButton = () => {
    const tagRegistration = async () => {
      const baseUrl =
        "https://zy2bouzqn2.execute-api.ap-northeast-1.amazonaws.com/Dev/tag-create";
      const postBody = {
        Keys: {
          tag_name: tagTitle,
          tag_color: color,
        },
      };
      await axios
        .post(baseUrl, postBody)
        .then((response) => {
          console.log(response);
          navigate("/admin/tag");
        })
        .catch((error) => {
          console.log(error);
        });
    };
    tagRegistration();
  };
  const handleBackButton = () => {
    navigate("../");
  };

  const colorList = [
    "#4AC6B6",
    "#F08C34",
    "#398175",
    "#EC5b56",
    "#7855E7",
    "#F38772",
    "#3983DC",
    "#EEACC8",
  ];

  return (
    <DefaultLayout pageTitle="Tag Registration">
      <link
        href="https://cdn.jsdelivr.net/npm/bootstrap@5.1.0/dist/css/bootstrap.min.css"
        rel="stylesheet"
        integrity="sha384-KyZXEAg3QhqLMpG8r+8fhAXLRk2vvoC2f3B09zVXn8CA5QIVfZOJ3BCsw2P0p/We"
        crossOrigin="anonymous"
      />

      <div className="container mt-3">
        <form className="needs-validation" noValidate>
          <div className="row align-items-center mt-3">
            <label htmlFor="tag_title" className="col-2 form-label fw-bold">
              Tag title*
            </label>
            <div className="col-10">
              <input
                type="text"
                className="form-control"
                id="tag_title"
                placeholder="ex) Brand Name"
                required
                value={tagTitle}
                onChange={(e) => setTagTitle(e.target.value)}
              />
              <div className="invalid-feedback">Input the title</div>
            </div>
          </div>

          <div className="row align-items-center mt-3">
            <label
              htmlFor="exampleColorInput"
              className="col-2 form-label fw-bold"
            >
              Color
            </label>
            <div className="col-10">
              <input
                type="color"
                disabled
                className="form-control form-control-color"
                id="exampleColorInput"
                value={color}
                title="Select Color"
              />
            </div>
            <div className="col-2" />
            <div className="col-10">
              {colorList.map((color) => (
                <IconButton
                  onClick={() => setColor(color)}
                  key={color}
                  sx={{
                    backgroundColor: color,
                    width: 25,
                    height: 25,
                    mt: 1,
                    mr: 1,
                    "&:hover": { backgroundColor: color },
                  }}
                />
              ))}
            </div>
          </div>

          <div className="row justify-content-center mt-3 mb-5">
            <div className="col-3">
              <button
                type="button"
                className="btn btn-outline-dark w-100"
                onClick={handleBackButton}
              >
                Turn Back
              </button>
            </div>
            <div className="col-3">
              <button
                id="addProductCheckButton"
                type="button"
                className="btn btn-outline-primary w-100"
                onClick={handleSubmitButton}
              >
                Submit
              </button>
            </div>
          </div>
        </form>
      </div>
    </DefaultLayout>
  );
};

export default AdminTagRegistration;
