import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";

import Grid from "@mui/material/Grid";
// import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import SettingsIcon from "@mui/icons-material/Settings";
import AddIcon from "@mui/icons-material/Add";
// import { Divider } from "@mui/material";

import { DefaultLayout } from "../templates/DefaultLayout";

// type
import { FAQScanType, FAQContentsType } from "../../types/api/FAQScanType";
import { Button, Typography } from "@mui/material";

function AdminFAQList() {
  const { state } = useLocation() as { state: { defaultCategoryId?: string } };
  console.log(state);
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(true);
  const [FAQData, setFAQData] = useState<FAQScanType[]>();
  const [selectedFAQCategoryId, setSelectedFAQCategoryId] =
    useState<string>("");
  const scanFAQData = async (InputCategoryId?: string) => {
    const baseUrl =
      "https://bp6fjsioll.execute-api.ap-northeast-1.amazonaws.com/Dev/faq-scan";
    const postBody = {};
    await axios
      .post(baseUrl, postBody)
      .then((res) => {
        const rawData = res.data.Item as FAQScanType[];
        const rawDataCategorySorted = rawData.sort((a, b) =>
          a.faq_order > b.faq_order ? 1 : -1
        );
        const rawDataSorted = rawDataCategorySorted.map(
          (categoryData: FAQScanType) => ({
            faq_id: categoryData.faq_id,
            faq_name: categoryData.faq_name,
            faq_order: categoryData.faq_order,
            faq_visible: categoryData.faq_visible,
            contents: categoryData.contents.sort((a, b) =>
              a.contents_order > b.contents_order ? 1 : -1
            ),
          })
        );
        console.log(rawDataSorted);
        setFAQData(rawDataSorted);
        console.log(state);

        const defaultCategoryId =
          InputCategoryId ??
          (state && state.hasOwnProperty("defaultCategoryId")
            ? state.defaultCategoryId ?? ""
            : rawDataSorted[0].faq_id);
        console.log(defaultCategoryId);
        setSelectedFAQCategoryId(defaultCategoryId);
      })
      .catch((err) => {
        console.log(err);
      });
    setLoading(false);
  };

  useEffect(() => {
    scanFAQData();
  }, []);

  const handleDeleteButton = (contents_id: string) => async () => {
    const baseUrl =
      "https://zy2bouzqn2.execute-api.ap-northeast-1.amazonaws.com/Dev/faq-contents-delete";
    const postBody = {
      Keys: {
        faq_id: selectedFAQCategoryId,
        contents: { contents_id },
      },
    };
    await axios.post(baseUrl, postBody).then((response) => {
      console.log(response);
    });
    scanFAQData(selectedFAQCategoryId);
  };

  return (
    <DefaultLayout pageTitle="FAQ List">
      <link
        href="https://cdn.jsdelivr.net/npm/bootstrap@5.1.0/dist/css/bootstrap.min.css"
        rel="stylesheet"
        integrity="sha384-KyZXEAg3QhqLMpG8r+8fhAXLRk2vvoC2f3B09zVXn8CA5QIVfZOJ3BCsw2P0p/We"
        crossOrigin="anonymous"
      />
      {!loading && FAQData && (
        <Grid container spacing={1}>
          <Grid
            item
            xs={4}
            style={{
              paddingRight: "10px",
              borderStyle: "solid",
              borderColor: "rgba(0, 0, 0, 0.12)",
              borderWidth: "0 1px 0 0",
            }}
          >
            <div>Help Category</div>
            <List component="nav">
              {FAQData.map((FAQCategory) => (
                <ListItemButton
                  key={FAQCategory.faq_id}
                  onClick={() => setSelectedFAQCategoryId(FAQCategory.faq_id)}
                  selected={FAQCategory.faq_id === selectedFAQCategoryId}
                >
                  <ListItemText primary={FAQCategory.faq_name} />
                </ListItemButton>
              ))}
              <ListItemButton onClick={() => navigate("edit/category")}>
                <ListItemIcon sx={{ minWidth: 0, mr: 1 }}>
                  <SettingsIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText secondary="Help Category Setting" />
              </ListItemButton>
            </List>
          </Grid>
          <Grid item container xs={8}>
            {FAQData.find(
              (element) => element.faq_id === selectedFAQCategoryId
            )?.contents.map((faqContent) => (
              <Grid
                container
                key={faqContent.subject}
                spacing={1}
                sx={{ mb: 1, ml: 1, pb: 1 }}
                style={{
                  borderStyle: "solid",
                  borderColor: "rgba(0, 0, 0, 0.12)",
                  borderWidth: "0 0 1px 0",
                }}
              >
                <Grid
                  item
                  container
                  justifyContent="center"
                  alignItems="center"
                >
                  <Grid item xs={3}>
                    <Typography>Order</Typography>
                  </Grid>
                  <Grid item xs={5}>
                    <Typography>{faqContent.contents_order}</Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Button
                      size="small"
                      color="primary"
                      style={{
                        textTransform: "none",
                        border: "1px solid",
                      }}
                      sx={{
                        ml: 1,
                        mr: 1,
                        p: 0,
                      }}
                      onClick={() => {
                        navigate(
                          `./edit/${selectedFAQCategoryId}/${faqContent.contents_id}`,
                          {
                            state: { defaultCategoryId: selectedFAQCategoryId },
                          }
                        );
                      }}
                    >
                      Edit
                    </Button>
                    <Button
                      size="small"
                      color="error"
                      style={{ textTransform: "none", border: "1px solid" }}
                      sx={{
                        ml: 1,
                        mr: 1,
                        p: 0,
                      }}
                      onClick={handleDeleteButton(faqContent.contents_id)}
                    >
                      Delete
                    </Button>
                  </Grid>
                </Grid>
                <Grid item xs={3}>
                  <Typography>Subject</Typography>
                </Grid>
                <Grid item xs={9}>
                  <Typography>{faqContent.subject}</Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography>Answer</Typography>
                </Grid>
                <Grid item xs={9}>
                  <Typography>{faqContent.answer}</Typography>
                </Grid>
              </Grid>
            ))}
            <Grid item>
              <Button
                color="inherit"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                style={{ textTransform: "none" }}
                onClick={() => {
                  navigate("/admin/faq/registration", {
                    state: { defaultCategoryId: selectedFAQCategoryId },
                  });
                }}
              >
                <AddIcon sx={{ color: "gray" }} />
                <Typography sx={{ color: "gray" }}>Add FAQ</Typography>
              </Button>
            </Grid>
          </Grid>
        </Grid>
      )}
    </DefaultLayout>
  );
}

export default AdminFAQList;
