import {
  useState,
  useMemo,
  useCallback,
  memo,
  MouseEvent,
  useEffect,
} from "react";
import { Button } from "@mui/material";
import { styled, createTheme, ThemeProvider } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import CircularProgress from "@mui/material/CircularProgress";

import { DefaultLayout } from "../templates/DefaultLayout";
import { ProductSummary } from "../atoms/ProductSummary";
import {
  ProductSummaryApiType,
  ProductSummaryApiType2,
} from "../../types/api/ProductSummaryApiType";
import { ProductSummaryType } from "../../types/ProductSummaryType";
import { productSummaryApiData } from "../../data/ProductSummaryApiData";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const testProducts: ProductSummaryApiType[] = productSummaryApiData;

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  "& .MuiToggleButtonGroup-grouped": {
    margin: theme.spacing(0.5),
    border: 0,
    "&.Mui-disabled": {
      border: 0,
    },
    "&:not(:first-of-type)": {
      borderRadius: theme.shape.borderRadius,
    },
    "&:first-of-type": {
      borderRadius: theme.shape.borderRadius,
    },
  },
}));

const StyledToggleButton = styled(ToggleButton)({
  outlineWidth: "1px",
  outlineStyle: "solid",
  margin: "2px",
  spacing: "2px",
});

const shippedCount = (winner_list: Array<any>) => {
  let res = 0;
  for (const item of winner_list) {
    if (item.shipping_status !== "unshipped") {
      res++;
    }
    return res;
  }
};

const getStatus = (product: ProductSummaryApiType2) =>
  new Date(product?.time_info.publication_at?.split("-")?.join("/")) >
  new Date()
    ? "Before Published"
    : !product?.entry_users?.winners
    ? "Published"
    : product?.entry_users?.winners?.length >
      (shippedCount(product?.entry_users?.winners) ?? 0)
    ? "Shipping"
    : "Done";

const visible = (
  product_status: string,
  beforePublishedProductOpen: boolean,
  publishedProductOpen: boolean,
  shippingProductOpen: boolean,
  doneProductOpen: boolean
): boolean => {
  const res =
    (product_status === "Before Published" && beforePublishedProductOpen) ||
    (product_status === "Published" && publishedProductOpen) ||
    (product_status === "Shipping" && shippingProductOpen) ||
    (product_status === "Done" && doneProductOpen);
  return res;
};

export const AdminProducts = () => {
  const navigate = useNavigate();
  const [formats, setFormats] = useState(() => [
    "beforePublished",
    "published",
    "shipping",
  ]);

  const handleFormat = (
    event: MouseEvent<HTMLElement>,
    newFormats: string[]
  ) => {
    setFormats(newFormats);
  };

  const [beforePublishedProductOpen, setBeforePublishedProductOpen] =
    useState<boolean>(true);
  const [publishedProductOpen, setPublishedProductOpen] =
    useState<boolean>(true);
  const [shippingProductOpen, setShippingProductOpen] = useState<boolean>(true);
  const [doneProductOpen, setDoneProductOpen] = useState<boolean>(false);
  const [BPSelected, setBPSelected] = useState<boolean>(true);
  const [productList, setProductList] = useState<ProductSummaryApiType2[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const getProductList = async () => {
      await setLoading(true);
      const url =
        "https://bp6fjsioll.execute-api.ap-northeast-1.amazonaws.com/Dev/products-scan";
      const postBody = { Keys: {} };
      await axios
        .post(url, postBody)
        .then((response) => {
          const newProductList: ProductSummaryApiType2[] = response.data.Item;
          console.log(newProductList);
          setProductList(newProductList);
        })
        .catch((error) => console.log(error));
      await setLoading(false);
    };
    getProductList();
  }, []);

  console.log(formats);
  const onClickBeforePublishedProductOpen = () => {
    setBeforePublishedProductOpen(!beforePublishedProductOpen);
    console.log(formats);
  };
  const onClickPublishedProductOpen = () => {
    setPublishedProductOpen(!publishedProductOpen);
  };
  const onClickShippingProductOpen = () => {
    setShippingProductOpen(!shippingProductOpen);
  };
  const onClickDoneProductOpen = () => {
    setDoneProductOpen(!doneProductOpen);
  };

  const handleProductDetailOpen = (productId: string) => () => {
    navigate("detail/" + productId);
  };

  return (
    <DefaultLayout pageTitle="Products List">
      <link
        href="https://cdn.jsdelivr.net/npm/bootstrap@5.1.0/dist/css/bootstrap.min.css"
        rel="stylesheet"
        integrity="sha384-KyZXEAg3QhqLMpG8r+8fhAXLRk2vvoC2f3B09zVXn8CA5QIVfZOJ3BCsw2P0p/We"
        crossOrigin="anonymous"
      />
      <Grid
        container
        sx={{
          display: "flex",
          justifyContent: "center",
        }}>
        <Grid item xs={0} md={2} lg={4}></Grid>
        <Grid
          item
          container
          spacing={1}
          xs={8}
          md={8}
          lg={4}
          sx={{
            display: "flex",
            justifyContent: "center",
          }}>
          <StyledToggleButtonGroup
            color="primary"
            value={formats}
            onChange={handleFormat}>
            <StyledToggleButton
              onClick={onClickBeforePublishedProductOpen}
              value="beforePublished"
              aria-label="beforePublished"
              sx={{
                "&.Mui-selected": {
                  outlineColor: "#907400",
                  backgroundColor: "#FFFEF5",
                  color: "#907400",
                  "&:hover": {
                    backgroundColor: "#fff9c4",
                  },
                },
              }}>
              Before Published
            </StyledToggleButton>
            <StyledToggleButton
              onClick={onClickPublishedProductOpen}
              value="published"
              aria-label="published"
              sx={{
                "&.Mui-selected": {
                  outlineColor: "#00227E",
                  backgroundColor: "#F2FBFF",
                  color: "#00227E",
                  "&:hover": {
                    backgroundColor: "#90caf9",
                  },
                },
              }}>
              Published
            </StyledToggleButton>
            <StyledToggleButton
              onClick={onClickShippingProductOpen}
              value="shipping"
              aria-label="shipping"
              sx={{
                "&.Mui-selected": {
                  outlineColor: "#9F0202",
                  backgroundColor: "#FFE3E3",
                  color: "#9F0202",
                  "&:hover": {
                    backgroundColor: "#ef9a9a",
                  },
                },
              }}>
              Shipping
            </StyledToggleButton>
            <StyledToggleButton
              onClick={onClickDoneProductOpen}
              value="done"
              aria-label="done"
              sx={{
                "&.Mui-selected": {
                  outlineColor: "#070707",
                  backgroundColor: "#D0D0D0",
                  color: "#070707",
                  "&:hover": {
                    backgroundColor: "#bdbdbd",
                  },
                },
              }}>
              Done
            </StyledToggleButton>
          </StyledToggleButtonGroup>
        </Grid>
        <Grid
          item
          xs={4}
          md={2}
          lg={4}
          sx={{
            display: "flex",
            justifyContent: "end",
            alignItems: "center",
          }}>
          <button
            type="button"
            className="btn btn-sm btn-outline-primary d-flex align-items-center"
            onClick={() => navigate("registration")}>
            <span>Add Product</span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              className="bi bi-plus-circle ms-1"
              viewBox="0 0 16 16">
              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
              <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
            </svg>
          </button>
        </Grid>
      </Grid>
      <Grid container spacing={1} direction="row" sx={{ marginTop: 3 }}>
        {loading ? (
          <Grid
            item
            xs={12}
            sx={{ justifyContent: "center", textAlign: "center" }}>
            <CircularProgress color="primary" />
          </Grid>
        ) : (
          productList &&
          productList?.map((product: ProductSummaryApiType2) => {
            return (
              visible(
                getStatus(product),
                beforePublishedProductOpen,
                publishedProductOpen,
                shippingProductOpen,
                doneProductOpen
              ) && (
                <Grid
                  item
                  xs={12}
                  md={6}
                  lg={4}
                  onClick={handleProductDetailOpen(product.product_id)}
                  key={product.product_id}>
                  <ProductSummary
                    key={product.product_id}
                    product={product}
                    status={getStatus(product)}
                  />
                </Grid>
              )
            );
          })
        )}
      </Grid>
      {/* </ThemeProvider> */}
    </DefaultLayout>
  );
};
