import { memo, VFC } from "react";
import { Route, Routes } from "react-router-dom";
import { StyledEngineProvider } from "@mui/material/styles";

import { AdminLogin } from "../components/pages/AdminLogin";
import { AdminDashBoard } from "../components/pages/AdminDashBoard";
import { Page404 } from "../components/pages/Page404";
import { AdminProducts } from "../components/pages/AdminProductsList";
import { AdminProductRegistration } from "../components/pages/AdminProductRegistration";
import { Dashboard } from "../components/dashboard/Dashboard";
import AdminCategoryRegistration from "../components/pages/AdminCategoryRegistration";
import AdminCategoryEdit from "../components/pages/AdminCategoryEdit";
import AdminTagRegistration from "../components/pages/AdminTagRegistration";
import AdminCategoryList from "../components/pages/AdminCategoryList";
import AdminTagEdit from "../components/pages/AdminTagEdit";
import AdminTagList from "../components/pages/AdminTagList";
import AdminProductDetail from "../components/pages/AdminProductDetail";
import { AdminProductEdit } from "../components/pages/AdminProductEdit";
import AdminFAQList from "../components/pages/AdminFAQList";
import AdminFAQAdd from "../components/pages/AdminFAQAdd";
import AdminFAQEditCategory from "../components/pages/AdminFAQEditCategory";
import AdminFAQEditContents from "../components/pages/AdminFAQEditContents";

export const Router: VFC = memo(() => {
  return (
    <StyledEngineProvider injectFirst>
      <Routes>
        <Route path="/admin">
          <Route path="" element={<AdminLogin />} />
          <Route path="dashboard" element={<AdminDashBoard />} />
          <Route path="products">
            <Route path="" element={<AdminProducts />} />
            <Route path="detail/:productId" element={<AdminProductDetail />} />
            <Route path="edit/:productId" element={<AdminProductEdit />} />
            <Route path="registration" element={<AdminProductRegistration />} />
          </Route>
          <Route path="category">
            <Route path="" element={<AdminCategoryList />} />
            <Route
              path="registration"
              element={<AdminCategoryRegistration />}
            />
            <Route path="edit/:categoryId" element={<AdminCategoryEdit />} />
          </Route>
          <Route path="tag">
            <Route path="" element={<AdminTagList />} />
            <Route path="registration" element={<AdminTagRegistration />} />
            <Route path="edit/:tagId" element={<AdminTagEdit />} />
          </Route>
          <Route path="faq">
            <Route path="" element={<AdminFAQList />} />
            <Route path="registration" element={<AdminFAQAdd />} />
            <Route
              path="edit/:faqId/:contentsId"
              element={<AdminFAQEditContents />}
            />
            <Route path="edit/category/" element={<AdminFAQEditCategory />} />
          </Route>
        </Route>
        <Route path="/*" element={<Page404 />} />
      </Routes>
    </StyledEngineProvider>
  );
});
