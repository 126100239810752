import * as React from "react";
import { useNavigate } from "react-router-dom";

import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import DashboardIcon from "@mui/icons-material/Dashboard";
import BarChartIcon from "@mui/icons-material/BarChart";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import DataUsageIcon from "@mui/icons-material/DataUsage";
import CategoryIcon from "@mui/icons-material/Category";
import TagIcon from "@mui/icons-material/Tag";
import QuizIcon from "@mui/icons-material/Quiz";
import LogoutIcon from "@mui/icons-material/Logout";

import { ProductIcon } from "./ProductIcon";

export const MainListItems = React.memo(() => {
  console.log("mainListItems was updated!!");
  const [open, setOpen] = React.useState(true);

  const handleClick = () => {
    setOpen(!open);
  };

  const navigate = useNavigate();
  return (
    <List component="nav">
      <ListItemButton onClick={() => navigate("/admin/dashboard")}>
        <ListItemIcon>
          <DashboardIcon />
        </ListItemIcon>
        <ListItemText primary="Dashboard" />
      </ListItemButton>
      <ListItemButton onClick={() => navigate("/admin/products")}>
        <ListItemIcon>
          <ProductIcon />
        </ListItemIcon>
        <ListItemText primary="Products" />
      </ListItemButton>
      <ListItemButton onClick={() => navigate("/admin/category")}>
        <ListItemIcon>
          <CategoryIcon />
        </ListItemIcon>
        <ListItemText primary="Categories" />
      </ListItemButton>
      <ListItemButton onClick={() => navigate("/admin/tag")}>
        <ListItemIcon>
          <TagIcon />
        </ListItemIcon>
        <ListItemText primary="Tags" />
      </ListItemButton>
      <ListItemButton
        onClick={() => {
          navigate("/admin/faq");
        }}>
        <ListItemIcon>
          <QuizIcon />
        </ListItemIcon>
        <ListItemText primary="FAQ" />
      </ListItemButton>
      <ListItemButton
        onClick={() => navigate("/admin/", { state: { logoutOrNot: true } })}>
        <ListItemIcon>
          <LogoutIcon />
        </ListItemIcon>
        <ListItemText primary="Logout" />
      </ListItemButton>
    </List>
  );
});
