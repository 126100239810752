import * as React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { borderRadius } from "@mui/system";

type LocationState = {
  logoutOrNot?: boolean;
};

function Copyright(props: any) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © Asuka.inc"}
      <Link color="inherit" href="https://mui.com/">
        Your Website
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const theme = createTheme();

export function AdminLogin() {
  const testUser = {
    email: "test@example.com",
    password: "test",
  };
  // const logoutOrNot = useSelector((state)=>state.logoutOrNot);
  const location = useLocation();
  const state = location.state as LocationState;
  const logoutOrNot = state && state.logoutOrNot;
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = React.useState<boolean>(false);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    // eslint-disable-next-line no-console
    console.log({
      email: data.get("email"),
      password: data.get("password"),
    });
    const userInfo = {
      email: data.get("email"),
      password: data.get("password"),
    };
    userInfo.email == testUser.email && userInfo.password == testUser.password
      ? navigate("/admin/dashboard")
      : setErrorMessage(true);
  };

  // const handleLogin = () =>{
  //     const email = react.dom
  //     alert('test');
  // };

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="p" variant="h5">
            Sign in
          </Typography>
          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 1 }}
          >
            {logoutOrNot && (
              <Typography
                component="p"
                color="error"
                sx={{
                  outline: "solid 1px",
                  borderRadius: "3px",
                  p: 1,
                }}
                align="center"
              >
                You have logged out successfully.
              </Typography>
            )}
            {errorMessage && (
              <Typography component="p" color="error">
                Not valid Email address or password.
              </Typography>
            )}
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
            />
            <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              //   onClick = {handleLogin}
            >
              Sign In
            </Button>
          </Box>
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
    </ThemeProvider>
  );
}
