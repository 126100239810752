import React, { useEffect, useState } from "react";

import IconButton from "@mui/material/IconButton";
import CancelIcon from "@mui/icons-material/Cancel";

import { DefaultLayout } from "../templates/DefaultLayout";
import { CategoryRegistrationType } from "../../types/api/CategoryRegistrationType";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const AdminCategoryRegistration = () => {
  const [photo, setPhoto] = useState<File>();
  const [eyeCatchURL, setEyeCatchURL] = useState<string>("");
  const [categoryTitle, setCategoryTitle] = useState<string>("");
  const [binaryData, setBinaryData] = useState<string>("");

  const [errorMessage, setErrorMessage] = useState<boolean>(false);

  const navigate = useNavigate();
  const handleSubmitButton = () => {
    const submitEditedCategory = async () => {
      const baseUrl =
        "https://zy2bouzqn2.execute-api.ap-northeast-1.amazonaws.com/Dev/category-create";
      if (photo && categoryTitle) {
        const newCategoryInfo: CategoryRegistrationType = {
          category_name: categoryTitle,
          category_eye_catch: {
            file_name: photo.name,
            file_data: binaryData.split("base64,")[1],
          },
        };
        const postBody = { Keys: newCategoryInfo };
        console.log(postBody);
        await axios
          .post(baseUrl, postBody)
          .then((response) => {
            console.log(response);
            navigate("../");
          })
          .catch((err) => console.log(err));
      } else {
        setErrorMessage(true);
      }
    };
    submitEditedCategory();
  };
  console.log(errorMessage);

  const handleFileInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      console.log(event.target.files[0]);
      setPhoto(event.target.files[0]);
    }
  };

  const handleEyeCatchDeleteButton = () => {
    setPhoto(undefined);
    setEyeCatchURL("");
  };

  useEffect(() => {
    if (photo) {
      setEyeCatchURL(URL.createObjectURL(photo));
      var reader = new FileReader();
      reader.readAsDataURL(photo);
      reader.onloadend = function () {
        var base64data = reader.result;
        if (typeof base64data === "string") {
          console.log(base64data.split("base64,")[1]);
          setBinaryData(base64data);
        }
      };
    }
  }, [photo]);

  return (
    <DefaultLayout pageTitle="Category Registration">
      {/* xs/sm/md/lg/xl: 画面サイズに応じて横幅を調整する数値（1〜12） */}
      <link
        href="https://cdn.jsdelivr.net/npm/bootstrap@5.1.0/dist/css/bootstrap.min.css"
        rel="stylesheet"
        integrity="sha384-KyZXEAg3QhqLMpG8r+8fhAXLRk2vvoC2f3B09zVXn8CA5QIVfZOJ3BCsw2P0p/We"
        crossOrigin="anonymous"
      />
      <div className="container">
        <form className="needs-validation" noValidate>
          <div className="row align-items-center mt-3">
            <label
              htmlFor="category_title"
              className="col-2 form-label fw-bold"
            >
              Category title*
            </label>
            <div className="col-10">
              <input
                type="text"
                className="form-control"
                id="category_title"
                placeholder="ex) T-shirt"
                value={categoryTitle}
                onChange={(event) => setCategoryTitle(event.target.value)}
                required
              />
              {errorMessage && !categoryTitle && (
                <div style={{ color: "red" }}>Category title is required</div>
              )}
            </div>
          </div>

          <div className="row align-items-center mt-3">
            <label
              htmlFor="category_title"
              className="col-2 form-label fw-bold"
            >
              Eyecatch*
            </label>
            {!eyeCatchURL && (
              <div className="col-10">
                <input
                  className="form-control"
                  id="InputFile"
                  type="file"
                  onChange={handleFileInput}
                  accept=".jpg, .jpeg"
                  required
                />
                {errorMessage && !photo && (
                  <div style={{ color: "red" }}>Eyecatch is required</div>
                )}
              </div>
            )}

            {eyeCatchURL && (
              <div className="row align-items-center col-10">
                <div
                  style={{
                    position: "relative",
                    width: "40%",
                  }}
                >
                  <IconButton
                    aria-label="delete image"
                    style={{
                      position: "absolute",
                      top: 10,
                      left: 10,
                      color: "#aaa",
                    }}
                    onClick={handleEyeCatchDeleteButton}
                  >
                    <CancelIcon />
                  </IconButton>
                  <img
                    src={eyeCatchURL}
                    style={{
                      width: "100%",
                    }}
                    alt="uploadedPhoto"
                  />
                  {photo && <p>{photo.name}</p>}
                </div>
              </div>
            )}
          </div>

          <div className="row justify-content-center mt-3 mb-5">
            <div className="col-3">
              <button
                type="button"
                onClick={() => navigate("../")}
                className="btn btn-outline-dark w-100"
              >
                Turn Back
              </button>
            </div>
            <div className="col-3">
              <button
                id="addProductCheckButton"
                className="btn btn-outline-primary w-100"
                type="button"
                onClick={handleSubmitButton}
              >
                Submit
              </button>
            </div>
          </div>
        </form>
      </div>
    </DefaultLayout>
  );
};

export default AdminCategoryRegistration;
