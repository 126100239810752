import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import CancelIcon from "@mui/icons-material/Cancel";
import IconButton from "@mui/material/IconButton";

import { DefaultLayout } from "../templates/DefaultLayout";
import {
  CategoryInfoType,
  CategoryScanInfoType,
} from "../../types/api/CategoryInfoType";
import axios from "axios";
import Switch from "@mui/material/Switch";

const AdminCategoryEdit = () => {
  const { categoryId } = useParams<{ categoryId: string }>();
  console.log(categoryId);

  const [categoryTitle, setCategoryTitle] = useState<string>("");
  const [deleteInput, setDeleteInput] = useState<string>("");
  const [photo, setPhoto] = useState<File>();
  const [eyeCatchURL, setEyeCatchURL] = useState<string>("");
  const [visible, setVisible] = useState<boolean>(true);
  const [fileName, setFileName] = useState<string>("");
  const [binaryData, setBinaryData] = useState<string>("");
  const navigate = useNavigate();
  useEffect(() => {
    const getCategoryInfo = async () => {
      const baseUrl =
        "https://bp6fjsioll.execute-api.ap-northeast-1.amazonaws.com/Dev/category-get-info";
      const postBody = { Keys: { category_id: categoryId } };
      await axios.post(baseUrl, postBody).then((response) => {
        const categoryInfo: CategoryScanInfoType = response.data.Item;
        console.log(categoryInfo);
        const { category_name, category_eye_catch, category_visible } =
          categoryInfo;
        setCategoryTitle(category_name);
        setEyeCatchURL(category_eye_catch);
        setFileName(category_eye_catch.split("/").slice(-1)[0]);
        setVisible(category_visible);
      });
      console.log(eyeCatchURL);
      await axios.get(eyeCatchURL).then((response) => {
        setPhoto(response.data);
      });
    };
    getCategoryInfo();
  }, []);
  console.log(categoryTitle);
  const handleEditCategoryTitle = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setCategoryTitle(event.target.value);
  };

  const handleEditDeleteInput = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setDeleteInput(event.target.value);
  };

  const handleFileInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      console.log(event.target.files[0]);
      setPhoto(event.target.files[0]);
      console.log(URL.createObjectURL(event.target.files[0]));
      setEyeCatchURL(URL.createObjectURL(event.target.files[0]));
      setFileName(event.target.files[0].name);
      var reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = () => {
        if (typeof reader.result === "string")
          setBinaryData(reader.result.split("base64,")[1]);
      };
    }
  };
  console.log(binaryData);

  const handleEyeCatchDeleteButton = () => {
    setFileName("");
    setPhoto(undefined);
    setEyeCatchURL("");
    setBinaryData("");
  };
  console.log(binaryData);
  const handleVisibleSwitch = () => {
    setVisible(!visible);
  };

  const handleSubmit = () => {
    if (categoryId === undefined) {
      alert("Unavailable Category ID.");
      return;
    }
    const submitEditedCategory = async () => {
      // if (photo) {
      //   var reader = new FileReader();
      //   reader.readAsDataURL(photo);
      //   reader.onload = () => {
      //     if (typeof reader.result === "string")
      //       setBinaryData(reader.result.split("base64,")[1]);
      //   };
      // }
      const baseUrl =
        "https://zy2bouzqn2.execute-api.ap-northeast-1.amazonaws.com/Dev/category-edit";
      if (categoryTitle) {
        const newCategoryInfo: CategoryInfoType = {
          category_name: categoryTitle,
          category_id: categoryId,
          category_eye_catch: {
            file_name: fileName,
            file_data: binaryData,
          },
          category_visible: visible,
        };
        const postBody = { Keys: newCategoryInfo };
        console.log(postBody);
        await axios
          .post(baseUrl, postBody, {
            headers: {
              "content-type": "application/json",
            },
          })
          .then((response) => {
            console.log(response);
            navigate("../");
          })
          .catch((err) => console.log(err));
      }
    };
    submitEditedCategory();
  };

  const handleDeleteButton = (categoryId: string | undefined) => () => {
    if (categoryId === undefined) {
      alert("Unavailable Category ID.");
      return;
    }
    console.log(categoryId);
    const submitDeleteCategory = async () => {
      const baseUrl =
        "https://zy2bouzqn2.execute-api.ap-northeast-1.amazonaws.com/Dev/category-delete";
      const postBody = { Keys: { category_id: categoryId } };
      console.log(postBody);
      await axios
        .post(baseUrl, postBody)
        .then((response) => {
          console.log(response);
          navigate("/admin/category");
        })
        .catch((err) => console.log(err));
    };
    submitDeleteCategory();
  };

  console.log(eyeCatchURL);
  console.log(photo);

  console.log("BLOB to URL", photo && URL.createObjectURL(photo));
  // const reader = new FileReader();
  // console.log("BLOB to Data", photo && reader.readAsDataURL(photo));
  // console.log("BLOB to String", photo && reader.readAsBinaryString(photo));

  return (
    <DefaultLayout pageTitle="Category Edit">
      {/* xs/sm/md/lg/xl: 画面サイズに応じて横幅を調整する数値（1〜12） */}
      <link
        href="https://cdn.jsdelivr.net/npm/bootstrap@5.1.0/dist/css/bootstrap.min.css"
        rel="stylesheet"
        integrity="sha384-KyZXEAg3QhqLMpG8r+8fhAXLRk2vvoC2f3B09zVXn8CA5QIVfZOJ3BCsw2P0p/We"
        crossOrigin="anonymous"
      />
      <div className="container">
        <form className="needs-validation" noValidate>
          <div className="row align-items-center mt-3">
            <div className="d-flex align-items-center">
              <p className="col-2 form-label fw-bold mb-2">Category Id</p>
              <p className="col-10 mb-2">{categoryId}</p>
            </div>
            <label
              htmlFor="category_title"
              className="col-2 form-label fw-bold"
            >
              Category title*
            </label>
            <div className="col-10">
              <input
                type="text"
                className="form-control"
                id="category_title"
                placeholder="ex) T-shirt"
                required
                onChange={handleEditCategoryTitle}
                value={categoryTitle}
              />
              <div className="invalid-feedback">Input the title</div>
            </div>

            <div className="row align-items-center mt-3">
              <label
                htmlFor="category_title"
                className="col-2 form-label fw-bold"
              >
                Eyecatch*
              </label>
              {!eyeCatchURL && (
                <div className="col-10">
                  <input
                    className="form-control"
                    id="InputFile"
                    type="file"
                    onChange={handleFileInput}
                    accept=".jpg, .jpeg"
                  />
                </div>
              )}

              {eyeCatchURL && (
                <div className="row align-items-center col-10">
                  <div
                    style={{
                      position: "relative",
                      width: "40%",
                    }}
                  >
                    <IconButton
                      aria-label="delete image"
                      style={{
                        position: "absolute",
                        top: 10,
                        left: 10,
                        color: "#aaa",
                      }}
                      onClick={handleEyeCatchDeleteButton}
                    >
                      <CancelIcon />
                    </IconButton>
                    <img
                      src={eyeCatchURL}
                      style={{
                        width: "100%",
                      }}
                      alt="uploadedPhoto"
                    />
                    {photo ? <p>{photo.name}</p> : <p>Unchanged image</p>}
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className="row align-items-center mt-3">
            <label
              htmlFor="category_title"
              className="col-2 form-label fw-bold"
            >
              Visible*
            </label>
            <div className="col-10">
              <span>Unvisible</span>
              <Switch checked={visible} onChange={handleVisibleSwitch} />
              <span>Visible</span>
            </div>
          </div>

          <div className="row justify-content-center mt-3 mb-5">
            <div className="col-3">
              <button
                type="button"
                className="btn btn-outline-dark w-100"
                onClick={() => navigate("../")}
              >
                Turn Back
              </button>
            </div>
            <div className="col-3">
              <button
                id="addProductCheckButton"
                className="btn btn-outline-primary w-100"
                type="button"
                onClick={handleSubmit}
              >
                Submit
              </button>
            </div>
          </div>
          <hr className="my-4" />

          <p>
            If you want to delete this tag, input <strong>“DELETE”</strong> and
            click Delete Button.{" "}
          </p>
          <div className="row align-items-center">
            <div className="col-10">
              <input
                type="text"
                className="form-control"
                id="category_delete"
                placeholder="DELETE"
                onChange={handleEditDeleteInput}
                value={deleteInput}
              />
              <div className="invalid-feedback">Input the title</div>
            </div>
            <div className="col-2">
              <button
                className="btn bg-danger text-white"
                type="button"
                id="category_delete"
                disabled={!(deleteInput === "DELETE")}
                onClick={handleDeleteButton(categoryId)}
              >
                Delete
              </button>
            </div>
          </div>
        </form>
      </div>
    </DefaultLayout>
  );
};

export default AdminCategoryEdit;
