import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Chart from "../dashboard/Chart";
import { DefaultLayout } from "../templates/DefaultLayout";

export function AdminDashBoard() {
  return (
    <DefaultLayout pageTitle="Dashboard">
      {/* Chart */}
      <Grid container spacing={1} direction="row" wrap="wrap">
        <Grid item xs={12} md={6} lg={4}>
          <Paper
            sx={{
              p: 2,
              display: "flex",
              flexDirection: "column",
              height: 240,
            }}
          >
            <Chart />
          </Paper>
        </Grid>
      </Grid>
    </DefaultLayout>
  );
}
