import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";

import Grid from "@mui/material/Grid";
// import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import SettingsIcon from "@mui/icons-material/Settings";
import AddIcon from "@mui/icons-material/Add";
// import { Divider } from "@mui/material";

import { DefaultLayout } from "../templates/DefaultLayout";

// type
import { FAQScanType, FAQContentsType } from "../../types/api/FAQScanType";
import { Button, Typography } from "@mui/material";

function AdminFAQList() {
  const { state } = useLocation() as { state: { defaultCategoryId: string } };
  const { defaultCategoryId } = state;
  console.log(defaultCategoryId);
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(true);
  const [FAQData, setFAQData] = useState<FAQScanType[]>();
  const [selectedFAQCategoryId, setSelectedFAQCategoryId] =
    useState<string>("");
  useEffect(() => {
    const scanFAQData = async () => {
      const baseUrl =
        "https://bp6fjsioll.execute-api.ap-northeast-1.amazonaws.com/Dev/faq-scan";
      const postBody = {};
      await axios
        .post(baseUrl, postBody)
        .then((res) => {
          const rawData = res.data.Item as FAQScanType[];
          const rawDataCategorySorted = rawData.sort((a, b) =>
            a.faq_order > b.faq_order ? 1 : -1
          );
          const rawDataSorted = rawDataCategorySorted.map(
            (categoryData: FAQScanType) => ({
              faq_id: categoryData.faq_id,
              faq_name: categoryData.faq_name,
              faq_order: categoryData.faq_order,
              faq_visible: categoryData.faq_visible,
              contents: categoryData.contents.sort((a, b) =>
                a.contents_order > b.contents_order ? 1 : -1
              ),
            })
          );
          setFAQData(rawDataSorted);
          setSelectedFAQCategoryId(rawDataSorted[0].faq_id);
          setCategoryId(defaultCategoryId);
        })
        .catch((err) => {
          console.log(err);
        });
      setLoading(false);
    };
    scanFAQData();
  }, []);

  const [subject, setSubject] = useState<string>("");
  const [answer, setAnswer] = useState<string>("");
  const [order, setOrder] = useState<number>();
  const [categoryId, setCategoryId] = useState<string>("");

  const handleSubjectChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSubject(event.target.value);
  };

  const handleChangeAnswer = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setAnswer(event.target.value);
  };

  const handleOrderChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setOrder(Number(event.target.value));
  };

  const handleCategoryIdChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setCategoryId(event.target.value);
  };

  const handleSubmitButton = async () => {
    const baseUrl =
      "https://zy2bouzqn2.execute-api.ap-northeast-1.amazonaws.com/Dev/faq-contents-create";
    const postBody = {
      Keys: {
        faq_id: categoryId,
        contents: {
          contents_order: order,
          subject,
          answer,
        },
      },
    };
    console.log(postBody);
    axios
      .post(baseUrl, postBody)
      .then((response) => {
        console.log(response);
        navigate("/admin/faq");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <DefaultLayout pageTitle="FAQ Add">
      <link
        href="https://cdn.jsdelivr.net/npm/bootstrap@5.1.0/dist/css/bootstrap.min.css"
        rel="stylesheet"
        integrity="sha384-KyZXEAg3QhqLMpG8r+8fhAXLRk2vvoC2f3B09zVXn8CA5QIVfZOJ3BCsw2P0p/We"
        crossOrigin="anonymous"
      />
      {!loading && FAQData && (
        <Grid container spacing={1}>
          <Grid
            item
            xs={4}
            style={{
              paddingRight: "10px",
              borderStyle: "solid",
              borderColor: "rgba(0, 0, 0, 0.12)",
              borderWidth: "0 1px 0 0",
            }}
          >
            <div>Help Category</div>
            <List component="nav">
              {FAQData.map((FAQCategory) => (
                <ListItemButton
                  key={FAQCategory.faq_id}
                  onClick={() =>
                    navigate("/admin/faq", {
                      state: { defaultCategoryId: FAQCategory.faq_id },
                    })
                  }
                >
                  <ListItemText primary={FAQCategory.faq_name} />
                </ListItemButton>
              ))}
              <ListItemButton onClick={() => console.log("ClickAdd")}>
                <ListItemIcon sx={{ minWidth: 0, mr: 1 }}>
                  <SettingsIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText secondary="Help Category Setting" />
              </ListItemButton>
            </List>
          </Grid>
          <Grid item container xs={8} sx={{ flexDirection: "column" }}>
            <div className="align-items-center mt-3">
              <label htmlFor="category" className="form-label fw-bold">
                Help Category*
              </label>
              <div className="col-10">
                <select
                  className="form-select"
                  id="category"
                  required
                  value={categoryId}
                  onChange={handleCategoryIdChange}
                >
                  <option value="" disabled>
                    Choose...
                  </option>
                  {FAQData.map((FAQCategory) => (
                    <option value={FAQCategory.faq_id}>
                      {FAQCategory.faq_name}
                    </option>
                  ))}
                </select>
                <div className="invalid-feedback">Select Category</div>
              </div>
            </div>

            <div className="align-items-center mt-3">
              <label htmlFor="product_title" className="form-label fw-bold">
                Order*
              </label>
              <div className="col-4">
                <input
                  type="number"
                  className="form-control"
                  id="order_input"
                  required
                  value={order}
                  onChange={handleOrderChange}
                />
                <div className="invalid-feedback">Input the title</div>
              </div>
            </div>

            <div className="align-items-center mt-3">
              <label htmlFor="product_title" className="form-label fw-bold">
                Subject*
              </label>
              <div className="col-10">
                <input
                  type="text"
                  className="form-control"
                  id="subject_input"
                  required
                  value={subject}
                  onChange={handleSubjectChange}
                />
                <div className="invalid-feedback">Input the title</div>
              </div>
            </div>

            <div className="align-items-center mt-3">
              <label htmlFor="product_title" className="form-label fw-bold">
                Answer*
              </label>
              <div className="col-10">
                <textarea
                  className="form-control"
                  id="subject_input"
                  required
                  value={answer}
                  onChange={handleChangeAnswer}
                />
                <div className="invalid-feedback">Input the title</div>
              </div>
            </div>

            <div className="col-10 row justify-content-center mt-5">
              <div className="col-4">
                <button
                  type="button"
                  className="btn btn-outline-dark w-100"
                  onClick={() => navigate("../")}
                >
                  Turn Back
                </button>
              </div>
              <div className="col-4">
                <button
                  id="addProductCheckButton"
                  className="btn btn-outline-primary w-100"
                  type="button"
                  data-bs-target="#addProductCheckModal"
                  onClick={handleSubmitButton}
                >
                  Submit
                </button>
              </div>
            </div>
          </Grid>
        </Grid>
      )}
    </DefaultLayout>
  );
}

export default AdminFAQList;
