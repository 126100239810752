import { createSlice } from "@reduxjs/toolkit";

export const slice = createSlice({
  name: "logoutOrNot",
  initialState: {
    logoutOrNot: false,
  },
  reducers: {
    updateLogoutOrNot: (state, action) => {
      state.logoutOrNot = action.payload.logoutOrNot;
    },
  },
});

export const { updateLogoutOrNot } = slice.actions;

export default slice.reducer;
