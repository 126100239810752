import IconButton from "@mui/material/IconButton";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { TagEditApiType } from "../../types/api/TagEditApiType";
import { TagInfoType } from "../../types/api/TagInfoType";
import { DefaultLayout } from "../templates/DefaultLayout";

const AdminTagEdit = () => {
  const navigate = useNavigate();
  const { tagId } = useParams();
  const [tagTitle, setTagTitle] = useState<string>("");
  const [color, setColor] = useState<string>("");
  const [deleteInput, setDeleteInput] = useState<string>("");

  const handleEditDeleteInput = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setDeleteInput(event.target.value);
  };

  const handleDeleteButton = () => {
    const deleteTag = async () => {
      const baseUrl =
        "https://zy2bouzqn2.execute-api.ap-northeast-1.amazonaws.com/Dev/tag-delete";
      const postBody = { Keys: { tag_id: tagId } };
      console.log(postBody);
      await axios
        .post(baseUrl, postBody)
        .then((response) => {
          console.log(response);
          navigate("../");
        })
        .catch((err) => {
          console.log(err);
        });
    };
    deleteTag();
  };

  const handleEditCommitButton = () => {
    const commitEdit = async () => {
      if (tagId === undefined) {
        alert("Unavailable Tag ID. Please Check it.");
        return;
      }
      const baseUrl =
        "https://zy2bouzqn2.execute-api.ap-northeast-1.amazonaws.com/Dev/tag-edit";
      const postBody: TagEditApiType = {
        Keys: { tag_id: tagId, tag_name: tagTitle, tag_color: color },
      };
      await axios
        .post(baseUrl, postBody)
        .then((response) => {
          console.log(response);
          navigate("../");
        })
        .catch((err) => {
          console.log(err);
        });
    };
    commitEdit();
  };

  useEffect(() => {
    const getTagInfo = async () => {
      const baseUrl =
        "https://bp6fjsioll.execute-api.ap-northeast-1.amazonaws.com/Dev/tag-get-info";
      const postBody = { Keys: { tag_id: tagId } };
      await axios
        .post(baseUrl, postBody)
        .then((response) => {
          const tagInfo: TagInfoType = response.data.Item;
          setTagTitle(tagInfo.tag_name);
          setColor(tagInfo.tag_color);
        })
        .catch((error) => {
          console.log(error);
        });
    };
    getTagInfo();
  }, []);

  const colorList = [
    "#4AC6B6",
    "#F08C34",
    "#398175",
    "#EC5b56",
    "#7855E7",
    "#F38772",
    "#3983DC",
    "#EEACC8",
  ];

  return (
    <DefaultLayout pageTitle="Tag Edit">
      <link
        href="https://cdn.jsdelivr.net/npm/bootstrap@5.1.0/dist/css/bootstrap.min.css"
        rel="stylesheet"
        integrity="sha384-KyZXEAg3QhqLMpG8r+8fhAXLRk2vvoC2f3B09zVXn8CA5QIVfZOJ3BCsw2P0p/We"
        crossOrigin="anonymous"
      />
      <div className="container mt-3">
        <form className="needs-validation" noValidate>
          <div className="d-flex align-items-center">
            <p className="col-2 form-label fw-bold mb-2">Category Id</p>
            <p className="col-10 mb-2">{tagId}</p>
          </div>
          <div className="row align-items-center mt-3">
            <label htmlFor="tag_title" className="col-2 form-label fw-bold">
              Tag title*
            </label>
            <div className="col-10">
              <input
                type="text"
                className="form-control"
                id="tag_title"
                placeholder="ex) Brand Name"
                required
                value={tagTitle}
                onChange={(e) => setTagTitle(e.target.value)}
              />
              <div className="invalid-feedback">Input the title</div>
            </div>
          </div>

          <div className="row align-items-center mt-3">
            <label
              htmlFor="exampleColorInput"
              className="col-2 form-label fw-bold"
            >
              Color
            </label>
            <div className="col-10">
              <input
                type="color"
                className="form-control form-control-color"
                id="exampleColorInput"
                value={color}
                title="Select Color"
                onChange={(e) => {
                  setColor(e.target.value);
                }}
              />
            </div>
            <div className="col-2" />
            <div className="col-10">
              {colorList.map((color) => (
                <IconButton
                  onClick={() => setColor(color)}
                  key={color}
                  sx={{
                    backgroundColor: color,
                    width: 25,
                    height: 25,
                    mt: 1,
                    mr: 1,
                    "&:hover": { backgroundColor: color },
                  }}
                />
              ))}
            </div>
          </div>

          <div className="row justify-content-center mt-3 mb-5">
            <div className="col-3">
              <button
                type="button"
                className="btn btn-outline-dark w-100"
                onClick={() => {
                  navigate("../");
                }}
              >
                Turn Back
              </button>
            </div>
            <div className="col-3">
              <button
                id="editCommitButton"
                className="btn btn-outline-primary w-100"
                type="button"
                onClick={handleEditCommitButton}
              >
                Submit
              </button>
            </div>
          </div>
          <hr className="my-4" />

          <p>
            If you want to delete this tag, input <strong>“DELETE”</strong> and
            click Delete Button.{" "}
          </p>
          <div className="row align-items-center">
            <div className="col-10">
              <input
                type="text"
                className="form-control"
                id="category_delete"
                placeholder="DELETE"
                onChange={handleEditDeleteInput}
                value={deleteInput}
              />
              <div className="invalid-feedback">Input the title</div>
            </div>
            <div className="col-2">
              <button
                className="btn bg-danger text-white"
                id="category_delete"
                type="button"
                disabled={!(deleteInput === "DELETE")}
                onClick={handleDeleteButton}
              >
                Delete
              </button>
            </div>
          </div>
        </form>
      </div>
    </DefaultLayout>
  );
};

export default AdminTagEdit;
