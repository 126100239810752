import React from "react";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import {
  ProductSummaryApiType2,
  winner2,
  participant,
} from "../../types/api/ProductSummaryApiType";
import { ProductSummaryType } from "../../types/ProductSummaryType";
import { BarChart, Bar, XAxis, YAxis, Label, LabelList } from "recharts";
import { useCallback, useMemo } from "react";

type Props = {
  product: ProductSummaryApiType2;
  status: string;
};

export const ProductSummary: React.FC<Props> = (props) => {
  const { product, status } = props;
  const { participants, winners } = product?.entry_users || {
    participants: [],
    winners: [],
  };
  const shippedCount = (winners: Array<winner2>) => {
    let res = 0;
    if (winners) {
      for (const item of winners) {
        if (item.delivery_status && item.delivery_status.delivery_at) {
          res++;
        }
        return res;
      }
    } else {
      return 0;
    }
  };
  const culcOccupancy = (participants: Array<participant>) => {
    let res: number = 0;
    if (participants) {
      for (let participant of participants) {
        res += participant.amount;
      }
    }
    return res;
  };
  const culcShipped = (winners: Array<winner2>) => {
    let res: number = 0;
    if (winners) {
      for (let winner of winners) {
        if (winner.delivery_status && winner.delivery_status.delivery_at) res++;
      }
    }
    return res;
  };
  const occupancyRate: number =
    Math.round(
      (culcOccupancy(participants) / product.sheets_info.limit) * 1000
    ) / 10;
  const shippingRate: number = winners
    ? Math.round((culcShipped(winners) / winners.length) * 1000) / 10
    : 0;
  const data =
    status === "Shipping"
      ? [{ name: "Shipping Status", rate: shippingRate }]
      : [{ name: "Occupancy Rate", rate: occupancyRate }];
  return (
    <Paper
      sx={{
        pt: 2,
        pl: 2,
        pr: 2,
        display: "flex",
        flexDirection: "column",
        height: 300,
        border: "1px solid",
        borderColor:
          status === "Done"
            ? "#070707"
            : status === "Shipping"
            ? "#9F0202"
            : status === "Published"
            ? "#00227E"
            : "#907400",
        backgroundColor:
          status === "Done"
            ? "#EAEAEA"
            : status === "Shipping"
            ? "#FFE3E3"
            : status === "Published"
            ? "#F2FBFF"
            : "#FFFEF5",
        justifyContent: "center",
        "&:hover": { cursor: "pointer", opacity: 0.6 },
      }}>
      <Typography
        component="p"
        noWrap
        sx={{
          overflow: "hidden",
          textAlign: "start",
          fontSize: 14,
        }}>
        {`#${product.product_id}`}
      </Typography>
      <Grid
        container
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          p: 1,
        }}>
        <Grid item xs={9}>
          <Typography
            component="p"
            noWrap
            sx={{
              overflow: "hidden",
              textAlign: "center",
              fontSize: 20,
            }}>
            {`${product.product_title}`}
          </Typography>
        </Grid>
      </Grid>
      <Grid
        container
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          p: 1,
        }}>
        <Grid item>
          <Typography
            component="p"
            sx={{
              wrap: "noWrap",
              overflow: "hidden",
              textAlign: "left",
            }}>
            {status === "Done"
              ? "Status"
              : status === "Before Published"
              ? "Publication Schedule"
              : "Roulette Schedule"}
          </Typography>
        </Grid>
        <Grid item>
          <Typography
            component="p"
            sx={{
              textAlign: "center",
              fontSize: 20,
            }}>
            {status === "Done"
              ? "Done"
              : status === "Before Published"
              ? `${product.time_info.publication_at}`
              : `${product.time_info.roulette_at}`}
          </Typography>
        </Grid>
      </Grid>
      {status !== "Done" && (
        <Grid
          container
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            p: 1,
          }}>
          <Grid item>
            <Typography
              component="p"
              sx={{
                textAlign: "left",
              }}>
              {!winners ? "Occupancy Status [%]" : "Shipping Status [%]"}
            </Typography>
          </Grid>
          <Grid item>
            {/* <ResponsiveContainer width="100%" height="100%"> レスポンシブに要変更*/}
            <BarChart layout="vertical" width={300} height={80} data={data}>
              <XAxis type="number" domain={[0, 100]} />
              <YAxis type="category" dataKey="name" hide />
              <Bar dataKey="rate" fill="#82ca9d">
                <LabelList dataKey="rate" position="center" />
              </Bar>
            </BarChart>
            {/* </ResponsiveContainer> */}
          </Grid>
        </Grid>
      )}
    </Paper>
  );
};
